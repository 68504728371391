import InterventionStep1 from './Step1'
import InterventionStep2 from './Step2'
import InterventionStep3 from './Step3'
import InterventionStep4 from './Step4'

export const InterventionSteps = [
    InterventionStep1,
    InterventionStep2,
    InterventionStep3,
    InterventionStep4,
]
