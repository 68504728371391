import { useEffect, useState } from 'react'
import { Spin, Tooltip } from 'antd'
import { Filters as FiltersComponent, Icon } from 'components'
import { SelectService, StatsService } from 'services'
import { Feedback, filtersForPage, formatFilters } from 'utils'
import { tooltips } from 'constants/tooltips'
import { getObjectTotal } from 'utils/computation'
import { useSelector } from 'react-redux'

const pages_endpoints_map = {
    demography: ['demography'],
    problems: ['demography', 'problems'],
    actions: ['actions'],
    references: ['references'],
    testimonies: ['testimonies'],
    map: ['map'],
}

export function useStats(page) {
    if (!(page in pages_endpoints_map))
        throw new Error('Page given is not valid to use the "useStats" hook.')

    const user = useSelector(state => state.user)

    const endpoints = pages_endpoints_map[page]
    const pageFilters = filtersForPage(page)

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})
    const [selects, setSelects] = useState({})
    const [total, setTotal] = useState(null)

    const fetchSelects = async () => {
        // Seulement un vrai utilisateur (pas impression publique) peut fetch les Selects
        if (!user.logged || user.infos.public) return
        if ((pageFilters || []).length === 0) return

        const selectsList = pageFilters.map(filter => filter.subject)

        try {
            const response = await SelectService.list(selectsList)
            setSelects(response)
        } catch (e) {
            Feedback.Error('loading-selects', e)
        }
    }

    useEffect(() => {
        fetchSelects()
    }, [user])

    const fetchStats = async (filters = {}) => {
        setLoading(true)

        try {
            const promises = endpoints.map(endpoint =>
                StatsService.load(endpoint, formatFilters(filters, 'api'))
            )
            const responses = await Promise.all(promises)
            setData(Object.assign(...responses))
        } catch (e) {
            Feedback.Error('loading-stats', e)
            setData({})
        } finally {
            setLoading(false)
        }
    }

    const StatsWrapper = ({ children: content, totalKey }) => {
        if (totalKey) setTotal(getObjectTotal(data[totalKey]))

        return (
            <div className='stats-wrap'>
                <Spin spinning={loading}>{content}</Spin>
            </div>
        )
    }

    const GraphBloc = ({ title, dataKey, graph: Graph, ...props }) => {
        const tooltip = tooltips[dataKey] ? (
            <Tooltip title={<div dangerouslySetInnerHTML={{ __html: tooltips[dataKey] }}></div>}>
                <Icon>infos</Icon>
            </Tooltip>
        ) : (
            <></>
        )

        return (
            <div {...props} className={'bloc-wrap ' + (props.className || '')}>
                <h3>
                    <i>{title}</i>
                    {tooltip}
                </h3>
                <div className='bloc-content'>
                    <Graph data={data[dataKey] !== undefined ? data[dataKey] : []} total={total} />
                </div>
            </div>
        )
    }

    const Filters = (
        <FiltersComponent
            filters={pageFilters}
            selects={selects}
            onFilterChanged={fetchStats}
            loading={loading}
        />
    )

    return {
        Filters,
        StatsWrapper,
        GraphBloc,
    }
}
