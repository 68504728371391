import { DATE_FORMAT, DATE_TIME_FORMAT } from 'constants/app'

/**
 * Utils : Props
 * ---
 * Dynamic props to feed different JSX tags (principally from Ant Design).
 */

export const datePickerProps = (time = false) => ({
    format: time ? DATE_TIME_FORMAT : DATE_FORMAT,
})

export const selectProps = () => ({
    notFoundContent: 'Aucun résultat...',
    showSearch: true,
    allowClear: true,
    filterOption: (input, option) => {
        let str = option.props.children
        if (typeof str !== 'string') str = option.props.children.props.value
        if (typeof str !== 'string') str = option.props.children.props['data-text']
        if (typeof str !== 'string') str = ''
        return str.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
})

export const textareaProps = () => ({
    autoSize: { minRows: 4, maxRows: 20 },
})

export const inputNumberProps = () => ({
    formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' '),
    parser: value => value.replace(/( *)/g, ''),
})
