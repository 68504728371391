import { Link } from 'react-router-dom'

function Logo(props) {
    const { link } = props

    return (
        <Link
            to={location => (link ? '/' : location)}
            className={'logo' + (link ? ' clickable' : '')}
        >
            <span>Action</span>
            <span>Médiation</span>
            <span className='bg-dash'>/</span>
        </Link>
    )
}

export default Logo
