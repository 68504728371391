import { Checkbox, Form, Input, Select } from 'antd'
import { useTableForm } from 'hooks'
import { renderSelectOptions, rules, selectProps } from 'utils'

const texts = {
    create: {
        title: 'Nouveau lieu',
    },
    default: {
        title: "Détails d'un lieu",
    },
    edit: {
        title: "Édition d'un lieu",
    },
}

function TableLocationForm(props) {
    const { selects, FormWrapper } = useTableForm(
        props,
        texts,
        ['LocationCategory', 'Partner'],
        data => ({
            ...data,
            partner_id: (data?.partners || []).map(p => p.id),
        })
    )

    return (
        <FormWrapper>
            <>
                <Form.Item label='Nom' name='label' rules={[rules.required]}>
                    <Input />
                </Form.Item>
                <Form.Item label='Adresse' name='address' rules={[rules.required]}>
                    <Input />
                </Form.Item>
                <Form.Item label='Longitude' name='lng' rules={[rules.required, rules.latlng]}>
                    <Input />
                </Form.Item>
                <Form.Item label='Latitude' name='lat' rules={[rules.required, rules.latlng]}>
                    <Input />
                </Form.Item>
                <Form.Item label='Catégorie' name='categorie_id' rules={[rules.required]}>
                    <Select {...selectProps()}>
                        {renderSelectOptions(selects.LocationCategory)}
                    </Select>
                </Form.Item>
                <Form.Item label='Partenaire' name='partner_id'>
                    <Select mode='multiple' {...selectProps()} maxTagCount={2}>
                        {renderSelectOptions(selects.Partner)}
                    </Select>
                </Form.Item>
                <Form.Item label='Archiver' name='is_archive' valuePropName='checked'>
                    <Checkbox />
                </Form.Item>
            </>
        </FormWrapper>
    )
}

export default TableLocationForm
