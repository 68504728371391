import { Header, Helmet } from 'components'
import { Donut, Bar } from 'components/Charts'
import { useStats } from 'hooks'
import { transformObjectData } from 'utils/charts'

const Genre = props => {
    const { data } = props
    return <Donut height={300} data={transformObjectData(data, 'label', 'value')} percentage />
}

const Age = props => {
    const { data } = props
    return <Bar height={300} data={transformObjectData(data, 'x', 'y')} percentage />
}

const Origin = props => {
    const { data } = props
    return <Bar height={300} data={transformObjectData(data, 'x', 'y')} percentage />
}

const Language = props => {
    const { data } = props
    return (
        <Donut
            height={300}
            diameter={200}
            data={transformObjectData(data, 'label', 'value')}
            percentage
        />
    )
}

const Interventions = props => {
    const { data } = props
    const value = data['value']
    return <p className='counter'>{value ? value : 0}</p>
}

const Veteran = props => {
    const { data } = props
    const percent = data['Vétéran (forces armées canadiennes)']
    return (
        <p className='counter'>
            {!isNaN(parseFloat(percent)) ? parseFloat(percent).toFixed(2) : 0} %
        </p>
    )
}

const Logs = props => {
    const { data } = props
    const value = data['value']
    return <p className='counter'>{!isNaN(value) ? value : 0}</p>
}

function DemographyPage() {
    const { Filters, StatsWrapper, GraphBloc } = useStats('demography')

    return (
        <div className='demography-page'>
            <Helmet title='Profil démographique' />
            <Header
                title='Profil démographique'
                subtitle='Tableau de bord'
                filters={Filters}
                showPrint
            />

            <StatsWrapper>
                <GraphBloc
                    title="Nombre d'interventions"
                    dataKey='interventions'
                    graph={Interventions}
                />
                <GraphBloc title='Nombre de rapports complétés' dataKey='logs' graph={Logs} />
                <GraphBloc title='Genre' dataKey='gender' graph={Genre} />
                <GraphBloc title="Tranche d'âge" dataKey='age' graph={Age} />
                <GraphBloc title='Origine culturelle' dataKey='origin' graph={Origin} />
                <GraphBloc title='Langue principale' dataKey='language' graph={Language} />
                <GraphBloc
                    title='Vétéran (forces armées canadiennes)'
                    dataKey='veteran'
                    graph={Veteran}
                />
            </StatsWrapper>
        </div>
    )
}

export default DemographyPage
