import { Form, InputNumber } from 'antd'
import { Fragment } from 'react'

function InterventionStep3() {
    const groups = [
        {
            label: 'Logement (stabilité résidentielle)',
            inputs: [
                { label: 'Itinérance chronique', key: 'logement_chronique' },
                { label: 'Itinérance cyclique', key: 'logement_cyclique' },
                { label: 'Itinérance situationnelle', key: 'logement_situationnelle' },
                { label: 'En appartement', key: 'logement_appt' },
            ],
        },
        {
            label: 'Toxicomanie',
            inputs: [{ label: '', key: 'toxicomanie' }],
        },
        {
            label: 'Alcoolisme',
            inputs: [{ label: '', key: 'alcoolisme' }],
        },
        {
            label: 'Jeu excessif',
            inputs: [{ label: '', key: 'jeu' }],
        },
        {
            label: 'Judiciaire',
            inputs: [{ label: '', key: 'judiciaire' }],
        },
        {
            label: 'Travail du sexe',
            inputs: [{ label: '', key: 'travail' }],
        },
        {
            label: 'Santé mentale',
            inputs: [{ label: '', key: 'sante_mentale' }],
        },
        {
            label: 'Santé physique',
            inputs: [{ label: '', key: 'sante_physique' }],
        },
        {
            label: 'Violence / intimidation (acteur)',
            inputs: [{ label: '', key: 'violence' }],
        },
        {
            label: 'Violence / intimidation (victime)',
            inputs: [{ label: '', key: 'violence_vict' }],
        },
        {
            label: 'Trouble comportement',
            inputs: [{ label: '', key: 'trouble_comportement' }],
        },
        {
            label: 'Fugue',
            inputs: [{ label: '', key: 'fugue' }],
        },
        {
            label: 'Nouvel arrivant / immigration (ne connaît pas les ressources)',
            inputs: [{ label: '', key: 'nouvel_arrivant' }],
        },
        {
            label: 'Besoins de base (vêtements, nourriture, transport, hygiène, etc.)',
            inputs: [{ label: '', key: 'besoin' }],
        },
    ]

    return (
        <div className='intervention-step-3'>
            {groups.map((group, i) => (
                <Fragment key={i}>
                    <h3>{group.label}</h3>
                    <div className='inputs-wrap'>
                        {group.inputs.map(input => (
                            <Form.Item key={input.key} name={input.key} label={input.label}>
                                <InputNumber min={0} />
                            </Form.Item>
                        ))}
                    </div>
                </Fragment>
            ))}
        </div>
    )
}

export default InterventionStep3
