import { useEffect, useState } from 'react'
import { Form, Input, Select } from 'antd'
import { listify } from 'constants/misc'
import { useTableForm } from 'hooks'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { renderSelectOptions, rules, selectProps, textareaProps } from 'utils'
import { UserService } from 'services'
import { Feedback } from 'utils'
import { USER_RANK_INTERVENANT } from 'constants/misc'

const texts = {
    default: {
        title: "Détails d'une entrée",
    },
    edit: {
        title: "Édition d'une entrée",
    },
    create: {
        title: "Ajout d'une entrée",
    },
}

function TableLogForm(props) {
    const [users, setUsers] = useState([])
    const user = useSelector(state => state.user)
    const { id: form_id } = useParams()

    const onLoadFormat = data => {
        const extra = { signalement_id: null }
        if (data?.signalement_orga_id) extra.signalement_id = 'orga-' + data.signalement_orga_id
        if (data?.signalement_loc_id) extra.signalement_id = 'loc-' + data.signalement_loc_id

        if (props.mode === 'create') extra.status = 1

        return {
            ...data,
            ...extra,
            status: String(data?.status || extra?.status) || null,
        }
    }

    const beforeSubmitFormat = data => {
        const { signalement_id } = data
        const extra = {
            signalement_orga_id: null,
            signalement_loc_id: null,
        }

        if (typeof signalement_id === 'string') {
            if (signalement_id.indexOf('orga') !== -1)
                extra.signalement_orga_id = signalement_id.replace('orga-', '')
            if (signalement_id.indexOf('loc') !== -1)
                extra.signalement_loc_id = signalement_id.replace('loc-', '')
        }

        if (props.mode === 'create') {
            extra.user_id = user?.infos?.id
            if (form_id) extra.form_id = form_id
        }

        return { ...data, ...extra }
    }

    const { selects, FormWrapper } = useTableForm(
        props,
        texts,
        ['Organisation', 'Location'],
        onLoadFormat,
        beforeSubmitFormat
    )

    const fetchIntervenant = async () => {
        try {
            const responseUsers = await UserService.list({
                rang: USER_RANK_INTERVENANT,
            })
            setUsers(responseUsers)
        } catch (e) {
            Feedback.Error('loading-users-logs-create', e)
        }
    }

    useEffect(() => {
        fetchIntervenant()
    }, [])

    return (
        <FormWrapper>
            <>
                <Form.Item label='Description' name='description' rules={[rules.required]}>
                    <Input.TextArea {...textareaProps()} spellcheck='true' />
                </Form.Item>
                <Form.Item label='Binôme' name='binome_id'>
                    <Select {...selectProps()}>
                        {renderSelectOptions(users, {
                            label: u => [u.name, u.surname].join(' '),
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label='Accompagnement' name='accompagnement_id'>
                    <Select {...selectProps()}>
                        {renderSelectOptions(selects.Organisation, { label: 'name' })}
                    </Select>
                </Form.Item>
                <Form.Item label='Réference' name='referent_id'>
                    <Select {...selectProps()}>
                        {renderSelectOptions(selects.Organisation, { label: 'name' })}
                    </Select>
                </Form.Item>
                <Form.Item label='Signalement' name='signalement_id'>
                    <Select {...selectProps()}>
                        {renderSelectOptions(selects.Location, {
                            label: v => (
                                <span className='select-option-custom' data-text={v.label}>
                                    <b>{v.label}</b>
                                    <small>Localisation</small>
                                </span>
                            ),
                            value: v => 'loc-' + v.id,
                        })}
                        {renderSelectOptions(selects.Organisation, {
                            label: v => (
                                <span className='select-option-custom' data-text={v.name}>
                                    <b>{v.name}</b>
                                    <small>Organisation</small>
                                </span>
                            ),
                            value: v => 'orga-' + v.id,
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label='Statut' name='status' rules={[rules.required]}>
                    <Select {...selectProps()}>{renderSelectOptions(listify('log-status'))}</Select>
                </Form.Item>
            </>
        </FormWrapper>
    )
}

export default TableLogForm
