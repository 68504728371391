import React, { Component } from 'react'
import { AutoComplete, Select } from 'antd'
import { renderAutocompleteOptions, renderSelectOptions } from 'utils'

/**
 * Component : Search
 * ---
 * An object that gives access to different kinds of Search.
 * Must be incorporated in a AntDesign.Form decorator.
 * Value + onChange events are given by the decorator, through props.
 *
 * @prop    { Function }                  onSearchValueChange     Called when the selected option is changed.
 * @prop    { Function }                  fetcher                 Function to fetch search results; should return Promise.
 * @prop    { Function }                  query                   Search parameter name.
 * @prop    { Object }                    filters                 (OPTIONAL) Filters applied to the service.
 * @prop    { Array }                     exclude                 (OPTIONAL) Values to exclude from the results.
 * @prop    { Function | String }         optionLabel             (OPTIONAL) strategy to derive label from each option
 * @prop    { Function | String }         optionValue             (OPTIONAL) strategy to derive value from each option
 * @prop    { String }                    controlType             (OPTIONAL) type of form control to use (input | select).
 */

// ABSTRACTION
export default class AbstractSearch extends Component {
    constructor(props) {
        super(props)
        this.state = { options: [] }

        this.handleChange = this.handleChange.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
    }

    /**
     * Handle change (search option click).
     */
    handleChange(value) {
        if (typeof value === 'undefined') value = null

        this.props.onChange && this.props.onChange(value)

        if (this.props.onSearchValueChange && this.props.controlType !== 'input')
            this.props.onSearchValueChange(
                this.state.options.find(option => option[this.props.optionValue] === value)
            )
    }

    /**
     * Handle change (autocomplete option click).
     */
    handleSelect(value) {
        if (typeof value === 'undefined') value = null

        if (this.props.onSearchValueChange) {
            const options = this.props.baseObject
                ? [this.props.baseObject, ...this.state.options]
                : [...this.state.options]
            this.props.onSearchValueChange(
                options.find(option => option[this.props.optionValue] === value)
            )
        }
    }

    /**
     * Handle search (search input key down).
     */
    handleSearch(value) {
        const { exclude = [], filters = {}, fetcher, query } = this.props
        const per_page = this.props.controlType == 'select' ? 100 : 25
        const setOptions = options => {
            options = options.filter(option => exclude.indexOf(option.id) === -1)
            this.setState({ options })
        }
        fetcher({ [query]: value, ...filters, per_page: per_page, page_number: 1 }).then(
            ({ data: options }) => {
                setOptions(options)
            }
        )
    }

    render() {
        let { options = [] } = this.state
        const { optionLabel, optionValue } = this.props

        // Populate the search with the baseObject when nothing fetched.
        if (options.length === 0 && this.props.value && this.props.baseObject)
            options = [this.props.baseObject]

        let Control = this.props.controlType === 'input' ? AutoComplete : Select
        const renderOptions =
            this.props.controlType === 'input' ? renderAutocompleteOptions : renderSelectOptions

        return (
            <Control
                showSearch
                allowClear
                optionLabelProp={this.props.controlType === 'input' ? 'value' : 'children'}
                disabled={this.props.disabled}
                value={this.props.value}
                placeholder={this.props.placeholder}
                style={this.props.style}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onChange={this.handleChange}
                onSelect={this.props.controlType === 'input' ? this.handleSelect : null}
                onSearch={this.handleSearch}
                onFocus={() => this.handleSearch(null)}
                notFoundContent={null}
                children={renderOptions(options, { optionLabel, optionValue })}
            />
        )
    }
}

AbstractSearch.defaultProps = {
    optionLabel: 'label',
    optionValue: 'id',
    baseObject: null,
    controlType: 'input',
}

AbstractSearch.displayName = 'Search'
