import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AbilityContext } from 'utils'
import { ABILITIES, updateAbilitiesFor } from 'constants/abilities'

export function AppAbility({ children }) {
    const user = useSelector(state => state.user)

    updateAbilitiesFor(user.logged ? user.infos : null)
    const [abilities, setAbilities] = useState(ABILITIES())

    useEffect(() => {
        updateAbilitiesFor(user.logged ? user.infos : null)
        setAbilities(ABILITIES())
    }, [user])

    return <AbilityContext.Provider value={abilities}>{children}</AbilityContext.Provider>
}
