import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Divider } from 'antd'
import { Helmet, Icon, Logo } from 'components'
import { READ } from 'constants/misc'
import { NavLink, useLocation } from 'react-router-dom'
import { Can, isPrint, ls } from 'utils'

function Nav() {
    const printMode = isPrint(useLocation())
    const storedIsCollapsed = ls.get('nav-collapsed') === true

    const [collapsed, setCollapsed] = useState(printMode ? false : storedIsCollapsed)
    const user = useSelector(state => state.user)

    const handleCollapsedChange = val => {
        setCollapsed(val)
        ls.set('nav-collapsed', val)
    }

    return (
        <nav className={`page-nav ${collapsed ? 'small' : ''}`}>
            <Helmet>
                <body data-collapsed-nav={collapsed} />
            </Helmet>

            <div className='logo-wrap'>
                <Button onClick={() => handleCollapsedChange(!collapsed)}>
                    <Icon>back</Icon>
                    <Icon>menu</Icon>
                    <Icon>to</Icon>
                    <i>Menu</i>
                </Button>
                <Logo link />
            </div>

            <div className='links'>
                <div>
                    <p>Tableau de bord</p>
                    <ul>
                        {links.public.map((link, i) => (
                            <li key={i}>
                                <NavLink to={link.to} exact>
                                    <Icon>{link.icon}</Icon>
                                    <i>{link.label}</i>
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>

                {user.logged && (
                    <>
                        <Divider />

                        <div>
                            <p>Administration</p>
                            <ul>
                                {links.admin.map((link, i) => (
                                    <Can do={READ} on={link.access} key={i}>
                                        <li>
                                            <NavLink to={link.to}>
                                                <Icon>{link.icon}</Icon>
                                                <i>{link.label}</i>
                                            </NavLink>
                                        </li>
                                    </Can>
                                ))}
                            </ul>
                        </div>
                    </>
                )}
            </div>
        </nav>
    )
}

const links = {
    public: [
        {
            icon: 'home_page',
            label: 'Accueil',
            to: '/',
        },
        {
            icon: 'demography_page',
            label: 'Profil démographique',
            to: '/stats/demography',
        },
        {
            icon: 'problems_page',
            label: 'Prob. rencontrées',
            to: '/stats/problems',
        },
        {
            icon: 'actions_page',
            label: 'Démarches entreprises',
            to: '/stats/actions',
        },
        {
            icon: 'references_page',
            label: 'Références',
            to: '/stats/references',
        },
        {
            icon: 'testimonies_page',
            label: 'Témoignages',
            to: '/stats/testimonies',
        },
        {
            icon: 'map_page',
            label: 'Carte',
            to: '/stats/map',
        },
    ],
    admin: [
        {
            icon: 'management_page',
            label: 'Gestion du site',
            to: '/admin/management',
            access: 'Portal',
        },
        {
            icon: 'log_page',
            label: 'Journal de bord',
            to: '/admin/log',
            access: 'Log',
        },
        {
            icon: 'intervention_page',
            label: 'Compléter un rapport',
            to: '/interventions/new',
            access: 'Intervention',
        },
    ],
}

export default Nav
