import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { parseToken, ls, rankFromToken, Feedback, isPrint } from 'utils'

import { login } from 'slices/user.slice'
import { StatsService } from 'services'
import { updateInterventionsCount } from 'slices/counter.slice'
import { AppAbility } from 'ability'

export function AppContext({ children }) {
    const user = useSelector(state => state.user)

    const location = useLocation()
    const dispatch = useDispatch()

    const printMode = isPrint(location)

    useEffect(() => {
        const token = parseToken(location)
        if (!token) return

        // Mode impression publique, sans utilisateur connecté
        if (printMode && token === 'null') {
            dispatch(login({ public: true }))
            return
        }

        const rank = rankFromToken(token)
        if (!rank) return

        ls.set('auth-token', token)
        dispatch(login({ rang: rank }))
    }, [])

    useEffect(() => {
        const fetchCount = async () => {
            try {
                const { value } = await StatsService.getCount()
                dispatch(updateInterventionsCount(value))
            } catch (e) {
                Feedback.Error(null, e)
            }
        }

        fetchCount()
    }, [])

    // En mode impression, on ne montre l'application qu'une fois l'utilisateur connecté (même en "utilisateur public")
    // Sinon la capture d'écran sera prise avant que les filtres ne soient appliqués
    if (printMode && !user.logged) return null

    return <AppAbility>{children}</AppAbility>
}
