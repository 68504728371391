import { useState } from 'react'
import { CountUp } from 'components'
import { getObjectTotal } from 'utils'

function HorizontalBar({ data, percentage, total }) {
    const [hint, setHint] = useState(false)
    const [show, setShow] = useState(false)
    setTimeout(() => setShow(true), 100)

    total = total || getObjectTotal(data)
    data = (data || []).map(d => ({ ...d, perc: (d.value / total) * 100 }))
    return (
        <ul className='horiz-histo-wrap' onMouseOut={() => setHint(false)}>
            {data.map((d, i) => (
                <li
                    key={i}
                    className={hint ? (d.label === hint.label ? 'hovered' : 'not-hovered') : ''}
                    onMouseEnter={() => setHint(d)}
                >
                    <span className='histo-label'>
                        <b>{d.label}</b>
                        {percentage ? (
                            <CountUp percent>{d.perc}</CountUp>
                        ) : (
                            <CountUp>{d.value}</CountUp>
                        )}
                    </span>

                    <span className='histo-bar'>
                        <span
                            className='histo-bar-fill'
                            style={{ width: `${show ? d.perc : 0}%` }}
                        ></span>
                    </span>
                </li>
            ))}
        </ul>
    )
}

export default HorizontalBar
