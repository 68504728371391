import { Form, Input } from 'antd'
import { useTableForm } from 'hooks'
import { rules } from 'utils'

const texts = {
    create: {
        title: 'Nouveau témoignage',
    },
    default: {
        title: "Détails d'un témoignage",
    },
    edit: {
        title: "Édition d'un témoignage",
    },
}

function TableTestimonialForm(props) {
    const { FormWrapper } = useTableForm(props, texts, [])

    return (
        <FormWrapper>
            <>
                <Form.Item label='Nom' name='name' rules={[rules.required]}>
                    <Input />
                </Form.Item>
                <Form.Item label='Entreprise' name='corporation' rules={[rules.required]}>
                    <Input />
                </Form.Item>
                <Form.Item label='Fonction' name='function'>
                    <Input />
                </Form.Item>
                <Form.Item label='Témoignage' name='text' rules={[rules.required]}>
                    <Input />
                </Form.Item>
            </>
        </FormWrapper>
    )
}

export default TableTestimonialForm
