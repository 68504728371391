import { useEffect, useState } from 'react'
import { Form, Input, Button } from 'antd'
import { UserService } from 'services'
import { Feedback, history, rules } from 'utils'
import { useLocation } from 'react-router-dom'
import { Header, Helmet } from 'components'

function ResetPasswordPage() {
    const [error, setError] = useState('')

    const { search } = useLocation()
    const token = new URLSearchParams(search).get('token')

    useEffect(() => {
        if (!token) history.replace('/')
    }, [])

    const handleResetPassword = async values => {
        try {
            await UserService.resetPassword({
                ...values,
                reset_password_token: token,
            })

            setError('')
            Feedback.Info(`Le mot de passe a bien été changé!`)
            history.replace('/')
        } catch (e) {
            setError('Une erreur est survenue.')
        }
    }

    return (
        <div className='reset-password-page'>
            <Helmet title='Changement de mot de passe' />
            <Header title='Changement de mot de passe' subtitle='Gestion de compte' />

            <Form onFinish={handleResetPassword} layout='vertical'>
                <Form.Item
                    label='Nouveau mot de passe'
                    name='password'
                    rules={[rules.required, rules.password]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label='Confirmation du mot de passe'
                    name='password_confirmation'
                    rules={[rules.required, rules.confirm_password]}
                >
                    <Input.Password />
                </Form.Item>
                <div className='buttons'>
                    <Button>Annuler</Button>
                    <Button htmlType='submit' className='blue'>
                        Changer le mot de passe
                    </Button>
                </div>
                {error && <span className='error'>{error}</span>}
            </Form>
        </div>
    )
}

export default ResetPasswordPage
