import { Form, Input, Select } from 'antd'
import { useTableForm } from 'hooks'
import { renderSelectOptions, rules, selectProps } from 'utils'

const texts = {
    create: {
        title: 'Nouvel organisme',
    },
    default: {
        title: "Détails d'un organisme",
    },
    edit: {
        title: "Édition d'un organisme",
    },
}

function TableOrganizationForm(props) {
    const { selects, FormWrapper } = useTableForm(props, texts, ['OrganisationCategory'])

    return (
        <FormWrapper>
            <>
                <Form.Item label='Nom' name='name' rules={[rules.required]}>
                    <Input />
                </Form.Item>
                <Form.Item label='URL' name='url' rules={[rules.url]}>
                    <Input />
                </Form.Item>
                <Form.Item label='Catégorie' name='categorie_id' rules={[rules.required]}>
                    <Select {...selectProps()}>
                        {renderSelectOptions(selects.OrganisationCategory)}
                    </Select>
                </Form.Item>
            </>
        </FormWrapper>
    )
}

export default TableOrganizationForm
