import { Header, Helmet } from 'components'
import { Bubble, HorizontalBar } from 'components/Charts'
import { useStats } from 'hooks'
import { transformObjectData } from 'utils/charts'

const Logement = props => {
    const { data, total } = props
    return (
        <Bubble
            height={300}
            data={transformObjectData(data, 'label', 'size', (_, i) => ({
                x: i,
                y: 0,
            }))}
            percentage
            total={total}
            minWidth={400}
        />
    )
}

const Health = props => {
    const { data, total } = props
    return (
        <HorizontalBar
            data={transformObjectData(data, 'label', 'value')}
            percentage
            total={total}
        />
    )
}

const Addiction = props => {
    const { data, total } = props
    return (
        <HorizontalBar
            data={transformObjectData(data, 'label', 'value')}
            percentage
            total={total}
        />
    )
}

const Other = props => {
    const { data, total } = props
    return (
        <HorizontalBar
            data={transformObjectData(data, 'label', 'value')}
            percentage
            total={total}
        />
    )
}

const Interventions = props => {
    const { data } = props
    const value = data['value']
    return <p className='counter'>{!isNaN(value) ? value : 0}</p>
}

const Logs = props => {
    const { data } = props
    const value = data['value']
    return <p className='counter'>{!isNaN(value) ? value : 0}</p>
}

function ProblemsPage() {
    const { Filters, StatsWrapper, GraphBloc } = useStats('problems')

    return (
        <div className='problems-page'>
            <Helmet title='Prob. rencontrées' />
            <Header
                title='Problématiques rencontrées'
                subtitle='Tableau de bord'
                filters={Filters}
                showPrint
            />

            <StatsWrapper totalKey='gender'>
                <GraphBloc
                    title="Nombre d'interventions"
                    dataKey='interventions'
                    graph={Interventions}
                />
                <GraphBloc title='Nombre de rapports complétés' dataKey='logs' graph={Logs} />
                <GraphBloc
                    title='Logement: stabilité résidentielle'
                    dataKey='logement'
                    graph={Logement}
                    style={{ width: '100%' }}
                />
                <GraphBloc title='Santé' dataKey='health' graph={Health} />
                <GraphBloc title='Dépendances' dataKey='addiction' graph={Addiction} />
                <GraphBloc title='Autres' dataKey='other' graph={Other} />
            </StatsWrapper>
        </div>
    )
}

export default ProblemsPage
