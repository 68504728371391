import moment from 'moment'
import { useSelector } from 'react-redux'
import { AuthButton, Icon } from 'components'
import { Formatter, isPrint, SdsExporter } from 'utils'
import { useLocation } from 'react-router-dom'
import { Button } from 'antd'

function Header({ title, subtitle, filters, showPrint, counter }) {
    const count = useSelector(state => state.counter.interventions)
    const currentFilters = useSelector(state => state.filters.content)

    const location = useLocation()
    const printMode = isPrint(location)

    const exporter = new SdsExporter({ path: location.pathname, div: '.page-content' })

    const handleExport = () => {
        exporter.setFilters(currentFilters)
        exporter.export()
    }

    return (
        <header className='page-header'>
            <div className='upper-header'>
                <div className='count-wrap'>
                    {counter == true && (
                        <>
                            <b>{Formatter.Number(count)}</b>
                            <span>
                                Interventions réalisées à <b>Montréal</b> en{' '}
                                {moment().format('YYYY')}
                            </span>
                        </>
                    )}
                </div>

                <div className='right-part'>
                    <div className='user-filters-wrap'>{filters && filters}</div>
                    <div className='user-actions' style={{ display: printMode ? 'none' : 'flex' }}>
                        <AuthButton />
                    </div>
                </div>
            </div>

            <div className='display-infos'>
                <small className='subtitle'>
                    {!printMode && showPrint && (
                        <Button onClick={handleExport}>
                            <Icon>print</Icon>
                            <i>Imprimer</i>
                        </Button>
                    )}
                    {subtitle}
                </small>
                <h1>{title}</h1>
            </div>
        </header>
    )
}

export default Header
