import React from 'react'
import ReactDOM from 'react-dom'
import AppRouter from 'router'

import { ConfigProvider } from 'antd'
import { Provider } from 'react-redux'
import store from 'store'

import 'antd/dist/antd.css'
import 'assets/scss/styles.scss'

import fr_FR from 'antd/lib/locale-provider/fr_FR'

import moment from 'moment'
import 'moment/locale/fr'
moment.locale('fr')

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={fr_FR}>
            <AppRouter />
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
)
