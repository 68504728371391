import { Header, Helmet } from 'components'
import { HorizontalBar } from 'components/Charts'
import { useStats } from 'hooks'
import { transformObjectData } from 'utils/charts'

const Clients = props => {
    const { data } = props
    return <HorizontalBar data={transformObjectData(data, 'label', 'value')} />
}

const Residents = props => {
    const { data } = props
    return <HorizontalBar data={transformObjectData(data, 'label', 'value')} />
}

const Interventions = props => {
    const { data } = props
    const value = data['value']
    return <p className='counter'>{!isNaN(value) ? value : 0}</p>
}

const Logs = props => {
    const { data } = props
    const value = data['value']
    return <p className='counter'>{!isNaN(value) ? value : 0}</p>
}

function ActionsPage() {
    const { Filters, StatsWrapper, GraphBloc } = useStats('actions')

    return (
        <div className='actions-page'>
            <Helmet title='Démarches entreprises' />
            <Header
                title='Démarches entreprises'
                subtitle='Tableau de bord'
                filters={Filters}
                showPrint
            />

            <StatsWrapper>
                <GraphBloc
                    title="Nombre d'interventions"
                    dataKey='interventions'
                    graph={Interventions}
                />
                <GraphBloc title='Nombre de rapports complétés' dataKey='logs' graph={Logs} />
                <GraphBloc
                    title='Démarches réalisées auprès de la clientèle'
                    dataKey='clients'
                    graph={Clients}
                />
                <GraphBloc
                    title='Démarches réalisées auprès des Riverains & Partenaires'
                    dataKey='residents'
                    graph={Residents}
                />
            </StatsWrapper>
        </div>
    )
}

export default ActionsPage
