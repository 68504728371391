import { header, queryAPI } from 'utils'

/**
 * Service : Selects
 * ---
 * Handles the fetching of some data to fill the <Select> in the JSX.
 * Interaction with the DB to manipulate these items.
 */

export const SelectService = {
    list,
    create,
    update,
    del,
}

const singularize = word => {
    if (word === 'caus') return word
    if (word[word.length - 1] === 's') {
        if (word.substring(word.length - 3) === 'ies') return word.slice(0, -3) + 'y'
        return word.slice(0, -1)
    }

    return word
}

async function list(selects) {
    const params = {
        method: 'GET',
        headers: header(),
    }

    const qs = selects.map(select => `models[]=${select}`).join('&')
    return await queryAPI(`selects?${qs}`, params)
}

async function create(slug, item) {
    const params = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...header(),
        },

        body: JSON.stringify({
            [singularize(slug)]: item,
        }),
    }

    return await queryAPI(slug, params, true)
}

async function update(slug, item) {
    const params = {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...header(),
        },

        body: JSON.stringify({
            [singularize(slug)]: item,
        }),
    }

    return await queryAPI(`${slug}/${item.id}`, params, true)
}

async function del(slug, id) {
    const params = {
        method: 'DELETE',
        headers: header(),
    }

    return await queryAPI(`${slug}/${id}`, params, true)
}
