import { getDateRange, header, queryAPI, serialize } from 'utils'
import { DATE_FORMAT_API } from 'constants/app'

/**
 * Service : Stats
 * ---
 * Fetchers for statistics pages
 */

export const StatsService = {
    load,
    getCount,
}

async function load(endpoint, filters = {}) {
    const params = {
        method: 'GET',
        headers: header(),
    }

    return await queryAPI(`statistics/${endpoint}?${serialize(filters)}`, params)
}

async function getCount() {
    const params = {
        method: 'GET',
        headers: header(),
    }

    const filters = { date: getDateRange('current-year').map(d => d.format(DATE_FORMAT_API)) }

    return await queryAPI(`statistics/interventions?${serialize(filters)}`, params)
}
