/**
 * Service : Intervention
 * ---
 * Interaction with the DB to manipulate interventions.
 */

import { header, queryAPI, serialize } from 'utils'

export const LogService = {
    list,
    get,
    update,
    create,
    del,
}

async function list(filters = {}) {
    const params = {
        method: 'GET',
        headers: header(),
    }

    return await queryAPI(`logs?${serialize(filters)}`, params)
}

async function get(id) {
    const params = {
        method: 'GET',
        headers: header(),
    }

    return await queryAPI(`logs/${id}`, params)
}

async function update(log) {
    const params = {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...header(),
        },

        body: JSON.stringify({
            log: log,
        }),
    }

    return await queryAPI(`logs/${log.id}`, params, true)
}

async function create(log) {
    const params = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...header(),
        },

        body: JSON.stringify({
            log: log,
        }),
    }

    return await queryAPI(`logs`, params, true)
}

async function del(id) {
    const params = {
        method: 'DELETE',
        headers: header(),
    }

    return await queryAPI(`logs/${id}`, params, true)
}
