import { Form, InputNumber, Tooltip } from 'antd'
import { Icon } from 'components'
import { tooltips } from 'constants/tooltips'

function InterventionStep4() {
    const generateToolTip = datakey => {
        const tooltip = tooltips.form[datakey] ? (
            <Tooltip
                title={<div dangerouslySetInnerHTML={{ __html: tooltips.form[datakey] }}></div>}
            >
                <Icon>infos</Icon>
            </Tooltip>
        ) : (
            <></>
        )

        return tooltip
    }

    const groups = [
        {
            label: 'Auprès de la clientèle',
            inputs: [
                { label: 'Observation', key: 'observation' },
                { label: 'Prise de contact', key: 'prise_contact' },
                { label: 'Médiation', key: 'mediation' },
                { label: 'Réduction des méfaits', key: 'reduction_mefaits' },
                { label: 'Gestion de crise', key: 'gestion_crise' },
                { label: 'Seringues et matériel ramassés', key: 'seringue_ramasse' },
                { label: 'Distribution titres de transport STM', key: 'distribution_ticket' },
                { label: 'Gestion des incivilités', key: 'gestion_incivilite' },
                { label: 'Premiers soins / naloxone', key: 'premier_soin' },
                { label: 'Intervention de soutien', key: 'intervention_soutien' },
                { label: 'Prise des signes vitaux', key: 'prise_signes_vitaux' },
            ],
        },
        {
            label: 'Auprès des Riverains & Partenaires',
            inputs: [
                // { label: 'Observation', key: 'observation_riv' },
                { label: 'Prise de contact', key: 'prise_contact_riv' },
                { label: 'Médiation', key: 'mediation_riv' },
                // { label: 'Réduction des méfaits', key: 'reduction_mefaits_riv' },
                // { label: 'Gestion de crise', key: 'gestion_crise_riv' },
                { label: 'Visite', key: 'visite_riv' },
                { label: 'Gestion de plainte', key: 'gestion_plainte_riv' },
                { label: 'Références', key: 'references_riv' },
            ],
        },
    ]

    return (
        <div className='intervention-step-4'>
            {groups.map((group, i) => (
                <div key={i}>
                    <h3>{group.label}</h3>
                    <div className='inputs-wrap'>
                        {group.inputs.map(input => (
                            <Form.Item
                                key={input.key}
                                name={input.key}
                                label={
                                    <div>
                                        {input.label}
                                        {generateToolTip(input.key)}
                                    </div>
                                }
                            >
                                <InputNumber min={0} />
                            </Form.Item>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default InterventionStep4
