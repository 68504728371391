export function getSum(data, keys) {
    return keys.reduce((acc, key) => acc + data?.[key], 0)
}

export function getObjectTotal(data, key = 'value') {
    if (!data) return 0
    return Object.values(data).reduce(
        (a, b) => a + (b && typeof b[key] !== 'undefined' ? b[key] : b),
        0
    )
}

export function getArraysTotal(data) {
    if (!data) return 0
    return Object.values(data).reduce((a, b) => {
        if (Array.isArray(b)) return a + b.reduce((acc, v) => acc + v.value, 0)
        else return a
    }, 0)
}
