import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Link } from 'react-router-dom'
import { Dropdown, Menu, Avatar, Form, Modal, Input, Button } from 'antd'
import { Icon } from 'components'

import { login, logout, update } from 'slices/user.slice'
import { unset } from 'slices/filters.slice'

import { Feedback, history, ls, rules } from 'utils'
import { UserService } from 'services'
import { describe, EDIT } from 'constants/misc'
import { TableUserForm } from './TableForms'

function AuthButton(props) {
    const [authModalOpen, setAuthModalOpen] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [resetModalOpen, setResetModalOpen] = useState(false)

    const [errorLogin, setErrorLogin] = useState('')
    const [errorReset, setErrorReset] = useState('')

    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const handleLogin = async values => {
        try {
            const [user, token] = await UserService.login(values.email, values.password)
            ls.set('auth-token', token)

            dispatch(login(user))
            dispatch(unset())
            setAuthModalOpen(false)
            setErrorLogin('')

            Feedback.Info(`Bienvenue, ${user.surname} ${user.name}!`)
        } catch (e) {
            setErrorLogin('Mauvaise combinaison de courriel/mot de passe.')
        }
    }

    const handleLogout = async () => {
        try {
            await UserService.logout()
            ls.remove('auth-token')

            dispatch(logout())
            dispatch(unset())

            history.push('/')
        } catch (e) {
            Feedback.Error(null, e)
        }
    }

    const handleForgotPassword = async values => {
        try {
            await UserService.forgotPassword(values)
            setResetModalOpen(false)
            setErrorReset('')

            Feedback.Info('Le courriel de réinitialisation a été envoyé!')
        } catch (e) {
            console.log(e)
            setErrorReset("L'adresse courriel saisie n'existe pas.")
        }
    }

    const handleResetModalOpen = () => {
        setAuthModalOpen(false)
        setResetModalOpen(true)
    }

    const handleEditModalClose = async (forceRefresh = false) => {
        if (!forceRefresh) return setEditModalOpen(false)

        try {
            const response = await UserService.get(user?.infos?.id)
            dispatch(update(response))
            setEditModalOpen(false)
        } catch (e) {
            Feedback.Error(null, e)
        }
    }

    const handleAvatarError = async () => {
        try {
            // On récupère un lien Amazon S3 à jour
            const response = await UserService.get(user?.infos?.id)
            dispatch(update(response))
        } catch (e) {
            Feedback.Error(null, e)
        }
    }

    const menu = (
        <Menu>
            <Menu.Item key='0'>
                <div onClick={() => setEditModalOpen(true)}>
                    <Icon>user</Icon>Consulter le profil
                </div>
            </Menu.Item>
            <Menu.Item key='1'>
                <Link to={location => location} onClick={handleLogout}>
                    <Icon>logout</Icon>Se déconnecter
                </Link>
            </Menu.Item>
        </Menu>
    )

    return (
        <>
            {user.logged ? (
                <Dropdown overlay={menu}>
                    <div className='menu-btn'>
                        {user?.infos?.avatar_url ? (
                            <Avatar src={user?.infos?.avatar_url} onError={handleAvatarError} />
                        ) : (
                            <Avatar>
                                {(user?.infos?.surname?.charAt(0) || '') +
                                    (user?.infos?.name?.charAt(0) || '')}
                            </Avatar>
                        )}

                        <span className='user-descr'>
                            {`${user.infos.surname} ${user.infos.name}`}
                            <small>
                                {user.infos?.poste ||
                                    describe('user-ranks', user.infos.rang)?.label}
                            </small>
                        </span>
                        <Icon>dropdown</Icon>
                    </div>
                </Dropdown>
            ) : (
                <div className='login-btn' onClick={() => setAuthModalOpen(true)}>
                    <Icon>login</Icon>
                    <span>Se connecter</span>
                </div>
            )}

            <Modal
                visible={authModalOpen}
                footer={null}
                closable={true}
                onCancel={() => setAuthModalOpen(false)}
                className='form-modal login-modal'
            >
                <Form onFinish={handleLogin} layout='vertical'>
                    <h3>Authentification</h3>
                    <Form.Item label='Courriel' name='email' rules={[rules.required, rules.email]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label='Mot de passe' name='password' rules={[rules.required]}>
                        <Input.Password />
                    </Form.Item>
                    <Button type='link' className='forgot-pwd-btn' onClick={handleResetModalOpen}>
                        Mot de passe oublié
                    </Button>
                    <div className='buttons'>
                        <Button onClick={() => setAuthModalOpen(false)}>Annuler</Button>
                        <Button htmlType='submit' className='blue'>
                            <Icon>login</Icon>
                            Se connecter
                        </Button>
                    </div>
                    {errorLogin && <span className='error'>{errorLogin}</span>}
                </Form>
            </Modal>

            <Modal
                visible={editModalOpen}
                onCancel={() => handleEditModalClose(false)}
                footer={null}
                className='form-modal table-modal user-modal'
            >
                <TableUserForm
                    mode={'edit'}
                    actions={{
                        edit: {
                            ability: { do: EDIT, on: 'User' },
                            handler: UserService.update,
                        },
                    }}
                    onClose={forceRefresh => handleEditModalClose(forceRefresh)}
                    baseData={user.infos}
                    restricted
                />
            </Modal>

            <Modal
                visible={resetModalOpen}
                footer={null}
                closable={true}
                onCancel={() => setResetModalOpen(false)}
                className='form-modal login-modal'
            >
                <Form onFinish={handleForgotPassword} layout='vertical'>
                    <h3>Réinitialiser le mot de passe</h3>
                    <Form.Item label='Courriel' name='email' rules={[rules.required, rules.email]}>
                        <Input />
                    </Form.Item>
                    <div className='buttons'>
                        <Button onClick={() => setResetModalOpen(false)}>Annuler</Button>
                        <Button htmlType='submit' className='blue'>
                            Envoyer un e-mail
                        </Button>
                    </div>
                    {errorReset && <span className='error'>{errorReset}</span>}
                </Form>
            </Modal>
        </>
    )
}

export default AuthButton
