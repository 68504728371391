import { Header, Helmet, Map } from 'components'
import { useEffect, useState } from 'react'
import { StatsService } from 'services'
import { Feedback } from 'utils'

function MapPage() {
    const [data, setData] = useState(null)

    const fetchData = async () => {
        try {
            const points = await StatsService.load('map')
            setData(points)
        } catch (e) {
            Feedback.Error('loading-stats', e)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className='map-page'>
            <Helmet title='Carte' />
            <Header title='Carte' subtitle='Tableau de bord' counter={true} />

            <div className='map-wrap'>
                <Map data={data} />
            </div>
        </div>
    )
}

export default MapPage
