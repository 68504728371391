import { AbilityBuilder, Ability } from '@casl/ability'

import {
    USER_RANK_ADMIN,
    USER_RANK_INTERVENANT,
    USER_RANK_PARTNER,
    READ,
    EDIT,
    CREATE,
    DELETE,
    MANAGE,
    FILTER,
} from 'constants/misc'

let abilities = defineBaseAbilities()

/**
 * Updates the permissions for the current user.
 * @param   { Object }  u   The user in question
 */
export function updateAbilitiesFor(user) {
    abilities = defineBaseAbilities()
    abilities.update(defineRulesFor(user))
}

function defineBaseAbilities() {
    const { build } = new AbilityBuilder(Ability)
    return build()
}

/**
 * Build the permissions for the current user.
 */
function defineRulesFor(user) {
    const { can, rules } = new AbilityBuilder(Ability)

    if (!user) return rules
    const { rang: rank } = user

    switch (rank) {
        case USER_RANK_ADMIN:
            can([READ, EDIT, CREATE, MANAGE], 'Intervention')
            can([READ, EDIT, CREATE, DELETE], 'Log')
            can([FILTER], 'Schedule')
            can([READ, EDIT, CREATE, DELETE, FILTER], 'Partner')
            can([READ, EDIT, CREATE, DELETE, FILTER], 'Location')
            can([READ, EDIT, CREATE, DELETE, FILTER], 'Organisation')
            can([READ, EDIT, CREATE, DELETE, FILTER], 'Testimonial')
            can([READ, CREATE], 'Report')
            can([READ, MANAGE], 'Portal')
            can([READ, EDIT, CREATE, DELETE], 'User')
            break
        case USER_RANK_INTERVENANT:
            can([CREATE], 'Intervention')
            can([READ, EDIT, CREATE], 'Log')
            can([READ, EDIT], 'Intervention', { id: user.id })
            can([READ, EDIT], 'User', { id: user.id })
            break
        case USER_RANK_PARTNER:
            can([READ], 'Log')
            break
        default:
            return rules
    }

    return rules
}

export const ABILITIES = () => abilities
