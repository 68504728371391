import { Carousel as CarouselAntd } from 'antd'
import { Icon } from 'components'

const Arrow = ({ type, style, className, onClick }) => (
    <Icon style={style} className={className} onClick={onClick}>
        {type}
    </Icon>
)

function Carousel({ images }) {
    return (
        <CarouselAntd
            autoplay
            autoplaySpeed={5000}
            slidesToShow={5}
            slidesToScroll={5}
            centerPadding={100}
            arrows
            dots={false}
            prevArrow={<Arrow type='prev' />}
            nextArrow={<Arrow type='next' />}
        >
            {(images || []).map((image, i) => (
                <img key={i} src={image.src} alt={image.alt} onClick={image.onClick}></img>
            ))}
        </CarouselAntd>
    )
}

export default Carousel
