import React, { useEffect, useState } from 'react'
import { DatePicker, Form, Radio } from 'antd'
import moment from 'moment'
import Search from 'components/Search'
import { DATE_FORMAT_API } from 'constants/app'
import { rules } from 'utils'
import { LocationService } from 'services'
import Fuse from 'fuse.js'

// https://github.com/ant-design/ant-design/issues/22185

const CustomDatePicker = props => {
    const { value, onChange, ...other } = props
    const time = value ? moment(value) : null
    const triggerChange = (_, dateStr) => {
        onChange && onChange(dateStr)
    }

    return <DatePicker defaultValue={time} onChange={triggerChange} {...other} />
}

function fuzzySearch(list, label) {
    if (!label) return list
    const fuse = new Fuse(list, { keys: ['label'] })
    return fuse.search(label).map(result => result.item)
}

function InterventionStep1(props) {
    const { selects, form, intervention } = props
    const { Schedule = [], Partner = [] } = selects
    const [partnerId, setPartnerId] = useState(
        form.getFieldValue('partner_id') || intervention.partner_id || null
    )

    useEffect(() => setPartnerId(intervention.partner_id), [intervention.partner_id])

    function resetLocation(partner) {
        setPartnerId(partner?.id || null)
        form.resetFields(['location_id'])
    }

    async function searchPartner({ label }) {
        return {
            data: fuzzySearch(Partner, label),
        }
    }

    async function searchLocation({ label, ...params }) {
        const locations = await LocationService.list({ ...params, is_archive: 0 })
        return {
            data: fuzzySearch(locations.data, label),
        }
    }

    return (
        <div className='intervention-step-1'>
            <div className='inputs-wrap-1'>
                <Form.Item name='date' label='Date' rules={[rules.required]}>
                    {
                        <Radio.Group>
                            <Radio value={moment().add(-0, 'days').format(DATE_FORMAT_API)}>
                                Aujourd'hui
                            </Radio>
                            <Radio value={moment().add(-1, 'days').format(DATE_FORMAT_API)}>
                                Hier
                            </Radio>
                            <Radio value={moment().add(-2, 'days').format(DATE_FORMAT_API)}>
                                Il y a 2 jours
                            </Radio>
                            <Radio value={moment().add(-3, 'days').format(DATE_FORMAT_API)}>
                                Il y a 3 jours
                            </Radio>
                        </Radio.Group>
                    }
                </Form.Item>
                <Form.Item name='schedule_id' label='Horaire' rules={[rules.required]}>
                    <Radio.Group>
                        {Schedule.map((s, i) => (
                            <Radio key={i} value={s.id}>
                                {s.label}
                            </Radio>
                        ))}
                    </Radio.Group>
                </Form.Item>
            </div>
            <div className='inputs-wrap-2'>
                <Form.Item name='partner_id' label='Partenaire' rules={[rules.required]}>
                    <Search
                        controlType='select'
                        query='label'
                        baseObject={intervention.partner || null}
                        fetcher={searchPartner}
                        onSearchValueChange={resetLocation}
                    />
                </Form.Item>
                <Form.Item name='location_id' label='Lieu' rules={[rules.required]}>
                    <Search
                        controlType='select'
                        query='label'
                        baseObject={intervention.location || null}
                        fetcher={searchLocation}
                        filters={partnerId ? { partner_id: partnerId } : null}
                        disabled={!partnerId}
                    />
                </Form.Item>
            </div>
        </div>
    )
}

export default InterventionStep1
