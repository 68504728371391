import { Fragment } from 'react'
import { Form, InputNumber } from 'antd'

function InterventionStep2() {
    const groups = [
        {
            label: 'Sexe',
            inputs: [
                { label: 'Homme', key: 'sexe_homme' },
                { label: 'Femme', key: 'sexe_femme' },
                { label: 'Trans', key: 'sexe_trans' },
            ],
        },
        {
            label: 'Langue parlée',
            inputs: [
                { label: 'Français', key: 'langue_francais' },
                { label: 'Anglais', key: 'langue_anglais' },
                { label: 'Autres / inconnus', key: 'langue_autre' },
            ],
        },
        {
            label: 'Fréquence de rencontre',
            inputs: [
                { label: 'Nouvel usager', key: 'frequence_nouveau' },
                { label: 'Usager régulier', key: 'frequence_regulier' },
                { label: 'Usager cyclique', key: 'frequence_cyclique' },
            ],
        },
        {
            label: 'Origine culturelle',
            inputs: [
                { label: 'Caucasien', key: 'origine_canadien' },
                { label: 'Minorité visible', key: 'origine_immigrant' },
                { label: 'Autochtone / Inuit', key: 'origine_autochtone' },
            ],
        },
        {
            label: 'Âge',
            inputs: [
                { label: '< 18 ans', key: 'age_inf18' },
                { label: '18-24 ans', key: 'age_1824' },
                { label: '25-39 ans', key: 'age_2539' },
                { label: '40-54 ans', key: 'age_4054' },
                { label: '55-64 ans', key: 'age_55' },
                { label: '+65 ans', key: 'age_sup65' },
            ],
        },
        {
            label: 'Vétéran',
            inputs: [{ label: '', key: 'veteran' }],
        },
    ]

    return (
        <div className='intervention-step-2'>
            {groups.map((group, i) => (
                <Fragment key={i}>
                    <h3>{group.label}</h3>
                    <div className='inputs-wrap'>
                        {group.inputs.map(input => (
                            <Form.Item key={input.key} name={input.key} label={input.label}>
                                <InputNumber min={0} />
                            </Form.Item>
                        ))}
                    </div>
                </Fragment>
            ))}
        </div>
    )
}

export default InterventionStep2
