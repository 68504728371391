import { Header, Helmet, Icon } from 'components'
import { useEffect, useState } from 'react'
import { TestimonialService } from 'services'
import { Feedback } from 'utils'

const Bloc = props => {
    const { author, children: content } = props

    return (
        <div className='bloc'>
            <>
                <span className='text'>
                    <Icon>quote</Icon>
                    <span>{content}</span>
                    <Icon>quote</Icon>
                </span>
                <div className='infos'>
                    <h4>{author.name}</h4>
                    <p>{author.function}</p>
                    <p>{author.corporation}</p>
                </div>
            </>
        </div>
    )
}

function TestimoniesPage() {
    const [testimonies, setTestimonies] = useState([])

    const fetchTestimonies = async () => {
        try {
            const list = await TestimonialService.list()
            setTestimonies(list)
        } catch (e) {
            Feedback.Error('loading-stats', e)
        }
    }

    useEffect(() => {
        fetchTestimonies()
    }, [])

    return (
        <div className='testimonies-page'>
            <Helmet title='Témoignages' />
            <Header title='Témoignages' subtitle='Tableau de bord' counter={true} />

            <div className='testimonies-wrap'>
                {testimonies.map((testimonial, i) => (
                    <Bloc
                        key={i}
                        author={{
                            name: testimonial.name,
                            function: testimonial.function,
                            corporation: testimonial.corporation,
                        }}
                    >
                        {testimonial.text}
                    </Bloc>
                ))}
            </div>
        </div>
    )
}

export default TestimoniesPage
