import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import { Badge, Button, DatePicker, Dropdown, Select } from 'antd'
import { Icon } from 'components'
import { FILTER } from 'constants/misc'
import {
    Can,
    datePickerProps,
    Formatter,
    getDateRange,
    isPrint,
    renderSelectOptions,
    selectProps,
} from 'utils'
import { formatFilters, getDefaultDateRange, onlyAbleToFilter, parseFilters } from 'utils/filters'
import { set } from 'slices/filters.slice'
import { useLocation } from 'react-router-dom'

const dateRangePresets = {
    'last-week': 'Dernière semaine',
    'last-month': 'Dernier mois',
    'last-year': 'Dernière année',
    'current-year': 'Année en cours',
    all: 'Toutes dates',
}

function Filters({ loading, selects, filters: baseFilters, onFilterChanged }) {
    const dispatch = useDispatch()

    const location = useLocation()
    const printMode = isPrint(location)

    const storedFilters = useSelector(state => state.filters.content)
    const urlFilters = parseFilters(location)

    const defaultFilters = urlFilters || storedFilters

    const [filters, setFilters] = useState(formatFilters(defaultFilters, 'display'))
    const [visible, setVisible] = useState({ dynamic: false, date: false })
    const [output, setOutput] = useState({ key: null, filters })
    const [edited, setEdited] = useState(false)

    // Compteur des filtres actifs.
    let filtersCount = 0
    for (const key in output.filters) {
        // La date n'est pas un filtre dynamique.
        if (key.includes('date')) continue
        if (filters[key] && filters[key] !== '' && filters[key].length > 0) filtersCount++
    }

    const handlePresetChange = preset => {
        const range = getDateRange(preset)
        setFilters({ ...filters, date: range, datePreset: preset })
        setEdited(true)
    }

    const handleChange = (value, key) => {
        const updated = key === 'date' ? { date: value, datePreset: undefined } : { [key]: value }
        setFilters({ ...filters, ...updated })
        setEdited(true)
    }

    const handleClear = key => {
        const cleared =
            key === 'date'
                ? { ...filters, ...getDefaultDateRange() }
                : { date: filters.date, datePreset: filters.datePreset }

        setOutput({
            key,
            filters: cleared,
        })
    }

    useEffect(() => {
        setVisible({ [output.key]: false })
        setFilters({ ...output.filters })

        const storedFilters = formatFilters({ ...output.filters }, 'storage')
        dispatch(set(storedFilters))

        setEdited(false)
        onFilterChanged(output.filters)
    }, [output])

    const renderSelect = (key, select) => {
        return (
            <Select
                {...selectProps()}
                onChange={val => handleChange(val, key)}
                value={filters[key]}
                mode='multiple'
                maxTagCount={0}
                maxTagPlaceholder={(filters[key] || []).length}
            >
                {renderSelectOptions(selects[select])}
            </Select>
        )
    }

    const ableToFilter = onlyAbleToFilter(baseFilters)

    const dynamicFilters = (
        <div className='form-wrap dynamic'>
            {baseFilters.map((filter, i) => (
                <Can do={FILTER} on={filter.subject} key={i}>
                    <div className='ctn'>
                        <label>{filter.label} :</label>
                        {renderSelect(filter.key, filter.subject)}
                    </div>
                </Can>
            ))}
            <div className='filters-buttons'>
                <Button type='link' onClick={() => handleClear('dynamic')}>
                    Réinitialiser
                </Button>
                <Button
                    className='orange'
                    onClick={() => setOutput({ key: 'dynamic', filters })}
                    disabled={!edited}
                >
                    Appliquer les filtres
                </Button>
            </div>
        </div>
    )

    const dateFilter = (
        <div className='form-wrap date'>
            <div className='ctn quick-actions'>
                {Object.keys(dateRangePresets).map(preset => (
                    <Button key={preset} onClick={() => handlePresetChange(preset)}>
                        {dateRangePresets[preset]}
                    </Button>
                ))}
            </div>
            <div className='ctn'>
                <label>Période :</label>
                <DatePicker.RangePicker
                    {...datePickerProps()}
                    value={(filters.date || []).map(d => (d ? moment(d) : null))}
                    onChange={dates => handleChange(dates, 'date')}
                />
            </div>
            <div className='filters-buttons'>
                <Button type='link' onClick={() => handleClear('date')}>
                    Réinitialiser
                </Button>
                <Button
                    className='orange'
                    onClick={() => setOutput({ key: 'date', filters })}
                    disabled={!edited}
                >
                    Appliquer les filtres
                </Button>
            </div>
        </div>
    )

    const dropdownProps = key => ({
        placement: 'bottomCenter',
        visible: visible[key],
        trigger: 'click',
        onVisibleChange: () => setVisible({ [key]: !visible[key] }),
    })

    return (
        <div className='user-filters'>
            {!printMode && ableToFilter.length > 0 && (
                <Dropdown overlay={dynamicFilters} {...dropdownProps('dynamic')}>
                    <Badge count={filtersCount}>
                        <Button disabled={loading}>
                            <Icon>filter</Icon>
                            Filtres
                            <Icon>down</Icon>
                        </Button>
                    </Badge>
                </Dropdown>
            )}
            <Dropdown overlay={dateFilter} {...dropdownProps('date')}>
                <div>
                    <Button disabled={loading}>
                        <Icon>filter_date</Icon>
                        {dateRangePresets[output.filters['datePreset']] ||
                            Formatter.DateRange(output.filters['date'])}
                        <Icon>down</Icon>
                    </Button>
                </div>
            </Dropdown>
        </div>
    )
}

export default Filters
