import { Header, Helmet } from 'components'

function AboutPage() {
    return (
        <div className='about-page'>
            <Helmet title='À propos' />
            <Header title='À propos' />

            <div className='about-wrap'>
                <p>
                    Action Médiation est un programme d’intervention psychosociale de la Société de
                    développement social déployé dans les espaces privés et publics du centre-ville
                    de Montréal et visant à venir en aide aux personnes marginalisées ou sans-abri
                    qui les fréquentent.
                </p>

                <h3>Missions du programme :</h3>

                <ul>
                    <li>
                        Aider et accompagner les personnes marginalisées ou sans-abri rencontrées
                        dans leur démarche de réinsertion en les référant vers des ressources
                        adaptées.
                    </li>
                    <li>
                        Assurer une cohabitation optimale entre les différents usagers des espaces
                        publics et privés (commerçants, agents d'accueil et de sécurité, citoyens)
                        et les personnes marginalisées ou sans-abri.
                    </li>
                </ul>

                <h3>Valeur du programme :</h3>

                <p>
                    Action Médiation est un intermédiaire, un lien entre différents milieux qui se
                    côtoient quotidiennement sans pour autant se connaître. Le programme repose donc
                    sur des valeurs de médiation et de collaboration qui visent à mettre l’humain au
                    cœur des préoccupations de chacun afin de créer un environnement de vie et/ou de
                    travail le plus harmonieux possible.
                </p>
            </div>
        </div>
    )
}

export default AboutPage
