import logoCanada from 'assets/images/logo_canada.png'
import logoSDS from 'assets/images/logo_sds.png'
import ExternalLink from 'components/ExternalLink'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <footer className='page-footer'>
            <div className='upper-footer'>
                <div className='images'>
                    <img src={logoSDS} alt='Logo SDS' />
                    <img src={logoCanada} alt='Logo Canada' />
                </div>
                <small>
                    Ce projet est financé par le gouvernement du Canada dans le cadre de la
                    Stratégie des partenariats de lutte contre l’itinérance et par la Société de
                    développement social.
                </small>
                <div className='links'>
                    <Link to='/about'>À propos</Link>
                </div>
            </div>
            <div className='lower-footer'>
                <small>©2021 Action Médiation</small>
                <small>
                    Réalisé par{' '}
                    <ExternalLink targetBlank label='04h11'>
                        https://04h11.com/
                    </ExternalLink>
                </small>
            </div>
        </footer>
    )
}

export default Footer
