import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { history, Can } from 'utils'
import { READ, EDIT, MANAGE } from 'constants/misc'

import {
    E404Page,
    HomePage,
    ResetPasswordPage,
    AboutPage,
    InterventionPage,
    ManagementPage,
    LogPage,
    ActionsPage,
    DemographyPage,
    MapPage,
    ProblemsPage,
    ReferencesPage,
    TestimoniesPage,
} from 'pages'
import { ErrorBoundary, Nav, Footer } from 'components'
import { AppContext } from 'context'

// Wrapper de Route qui ajoute la gestion d'accès à une page
function AppRoute({ component: Component, componentProps, abilities, ...routeProps }) {
    return (
        <Route
            {...routeProps}
            render={matchProps => {
                const Rendered = <Component {...componentProps} {...matchProps} />
                if (!abilities) return Rendered

                return (
                    <Can
                        do={abilities.do}
                        on={
                            typeof abilities.on === 'function'
                                ? abilities.on(matchProps)
                                : abilities.on
                        }
                        passThrough
                    >
                        {can => (can ? Rendered : <E404Page />)}
                    </Can>
                )
            }}
        />
    )
}

const routes = [
    {
        component: InterventionPage,
        path: '/interventions/new',
        abilities: {
            do: READ,
            on: 'Intervention',
        },
    },
    {
        component: InterventionPage,
        path: '/interventions/:id/:step',
        abilities: {
            do: EDIT,
            on: 'Intervention',
        },
    },
    {
        component: InterventionPage,
        path: '/interventions/:id',
        abilities: {
            do: EDIT,
            on: 'Intervention',
        },
    },
    {
        component: LogPage,
        path: '/admin/log',
        abilities: {
            do: READ,
            on: 'Log',
        },
    },
    {
        component: ManagementPage,
        path: '/admin/management/:option',
        abilities: {
            do: MANAGE,
            on: 'Portal',
        },
    },
    {
        component: ManagementPage,
        path: '/admin/management',
        abilities: {
            do: MANAGE,
            on: 'Portal',
        },
    },
    {
        component: DemographyPage,
        path: '/stats/demography',
    },
    {
        component: ProblemsPage,
        path: '/stats/problems',
    },
    {
        component: ActionsPage,
        path: '/stats/actions',
    },
    {
        component: ReferencesPage,
        path: '/stats/references',
    },
    {
        component: TestimoniesPage,
        path: '/stats/testimonies',
    },
    {
        component: MapPage,
        path: '/stats/map',
    },
    {
        component: AboutPage,
        path: '/about',
    },
    {
        component: ResetPasswordPage,
        path: '/reset_password',
    },
]

function AppRouter() {
    return (
        <div className='page-wrap'>
            <Router history={history}>
                <AppContext>
                    <Nav />
                    <div className='page-content'>
                        <ErrorBoundary>
                            <Switch>
                                {React.Children.toArray(routes.map(r => <AppRoute exact {...r} />))}
                                <Route exact path='/' component={HomePage} />
                                <Route path='*' component={E404Page} />
                            </Switch>
                        </ErrorBoundary>
                        <Footer />
                    </div>
                </AppContext>
            </Router>
        </div>
    )
}

export default AppRouter
