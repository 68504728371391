import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet, Header, Icon, Map, Carousel, CountUp } from 'components'
import { Button, Modal } from 'antd'
import parse from 'html-react-parser'
import { CustomBlockService, StatsService, UserService, PartnerService } from 'services'
import { Feedback, getDateRange, history } from 'utils'
import { USER_RANK_INTERVENANT } from 'constants/misc'
import { DATE_FORMAT_API } from 'constants/app'
import { getArraysTotal, getObjectTotal, getSum } from 'utils/computation'

const endpoints = ['demography', 'problems', 'actions', 'references']

const Bloc = props => {
    const { icon, title, descr, link, blocs, custom, noBG, children: content } = props

    const Title = props => {
        if (link)
            return (
                <h3>
                    <Link to={link}>
                        {props.children}
                        <Icon>to</Icon>
                    </Link>
                </h3>
            )

        return (
            <h3>
                <span>{props.children}</span>
            </h3>
        )
    }

    return (
        <div className={`bloc ${custom ? 'is-custom' : ''} ${noBG ? 'no-bg' : ''}`}>
            <Title>
                <Icon>{icon}</Icon>
                <i>{title}</i>
            </Title>
            {custom ? (
                <div className='custom-content-wrap'>{content}</div>
            ) : (
                <>
                    <p>{descr}</p>
                    <div className='content-wrap'>
                        {blocs ? (
                            <div className='data-blocs'>
                                {blocs.map((bloc, i) => (
                                    <span className={`of-${blocs.length}`} key={i}>
                                        <CountUp percent={bloc.percent}>{bloc.data || 0}</CountUp>
                                        <small>{bloc.label}</small>
                                    </span>
                                ))}
                            </div>
                        ) : (
                            content || <></>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

function HomePage() {
    const [customBlock, setCustomBlock] = useState({})
    const [stats, setStats] = useState({})
    const [map, setMap] = useState(null)
    const [total, setTotal] = useState(0)
    const [users, setUsers] = useState([])
    const [partners, setPartners] = useState([])
    const fetchBlock = async () => {
        try {
            const content = await CustomBlockService.get(1)
            setCustomBlock(content)
        } catch (e) {
            Feedback.Error('loading-home-stats', e)
        }
    }

    const fetchMap = async () => {
        try {
            const response = await StatsService.load('map')
            setMap(response)
        } catch (e) {
            Feedback.Error('loading-home-stats', e)
        }
    }

    const fetchStats = async () => {
        try {
            const filters = {
                date: getDateRange('current-year').map(d => d.format(DATE_FORMAT_API)),
            }

            const promises = endpoints.map(e => StatsService.load(e, filters))
            const responses = await Promise.all(promises)

            // Manipulations to help get % for Blocs #1 and #2
            // ref = array that contains the number of persons
            const tmpStats = Object.fromEntries(
                responses.map((response, i) => [endpoints[i], response])
            )
            const ref = Object.values(tmpStats?.demography?.gender || {}) || []

            setStats(tmpStats)
            setTotal(ref.reduce((a, b) => a + b, 0))
        } catch (e) {
            Feedback.Error('loading-home-stats', e)
            setStats({})
        }
    }

    const fetchCarousel = async () => {
        try {
            const responseUsers = await UserService.list({
                homepage: '1',
                rang: USER_RANK_INTERVENANT,
            })
            setUsers(responseUsers)

            const responsePartners = await PartnerService.list()
            setPartners(responsePartners)
        } catch (e) {
            Feedback.Error('loading-home-stats', e)
            setStats({})
        }
    }

    useEffect(() => {
        fetchBlock()
        fetchMap()
        fetchStats()
        fetchCarousel()
    }, [])

    const toPerc = number => {
        try {
            return (number / total) * 100
        } catch {
            return 0
        }
    }

    return (
        <div className='home-page'>
            <Helmet title='Accueil' />
            <Header title='Accueil' subtitle='Tableau de bord' counter={true} />
            <div className='intro-wrap'>
                <div className='intro-content'>
                    <p>
                        Action Médiation est un{' '}
                        <b>
                            programme de médiation sociale qui vise à venir en aide aux personnes en
                            situation ou à risque d’itinérance
                        </b>{' '}
                        tout en facilitant la cohabitation entre les différents milieux de vie à
                        Montréal.
                    </p>

                    <Button onClick={() => history.push('/stats/testimonies')}>
                        <Icon>testimonies_page</Icon>
                        Consulter les témoignages des participants impliqués
                        <Icon>to</Icon>
                    </Button>
                </div>

                <Bloc custom title={customBlock?.title} icon='infos'>
                    <p>{parse(customBlock?.content || '')}</p>
                </Bloc>
            </div>

            <div className='main-content-wrap'>
                <div className='map-wrap'>
                    <Map data={map} />
                </div>
                <div className='blocs-wrap'>
                    <Bloc
                        link='/stats/demography'
                        title='Profil démographique'
                        icon='demography_page'
                        descr='Profil des personnes rencontrées par les intervenants lors de leurs déplacements dans les différents lieux visés par le programme Action Médiation.'
                        blocs={[
                            {
                                data: toPerc(stats?.demography?.gender?.['Homme']),
                                percent: true,
                                label: 'Homme',
                            },
                            {
                                data: toPerc(stats?.demography?.gender?.['Femme']),
                                percent: true,
                                label: 'Femme',
                            },
                            {
                                data: toPerc(stats?.demography?.gender?.['Trans']),
                                percent: true,
                                label: 'Trans',
                            },
                        ]}
                    />
                    <Bloc
                        link='/stats/problems'
                        title='Prob. rencontrées'
                        icon='problems_page'
                        descr='Liste des principales problématiques auxquelles sont confrontées les personnes rencontrées par les intervenants dans les lieux visés par le programme Action Médiation.'
                        blocs={[
                            {
                                data: toPerc(
                                    getSum(stats?.problems?.logement, [
                                        'Chronique',
                                        'Cyclique',
                                        'Situationnel',
                                    ])
                                ),
                                percent: true,
                                label: 'Itinérance',
                            },
                            {
                                data: toPerc(stats?.problems?.addiction?.['Toxicomanie']),
                                percent: true,
                                label: 'Toxicomanie',
                            },
                            {
                                data: toPerc(stats?.problems?.health?.['Santé mentale']),
                                percent: true,
                                label: 'S. Mentale',
                            },
                        ]}
                    />
                    <Bloc
                        link='/stats/actions'
                        title='Démarches entreprises'
                        icon='actions_page'
                        descr='Les démarches sont les actions réalisées par les intervenants pour résoudre les différentes problématiques rencontrées dans les lieux visés par le programme Action Médiation.'
                        blocs={[
                            {
                                data:
                                    getObjectTotal(stats?.actions?.clients) +
                                    getObjectTotal(stats?.actions?.residents),
                                label: 'Démarches réalisées',
                            },
                        ]}
                    />
                    <Bloc
                        link='/stats/references'
                        title='Références réalisées'
                        icon='references_page'
                        descr='Étape clé du programme Action Médiation, référer la personne rencontrée vers un service ou une ressource le plus adapté pour lui venir en aide.'
                        blocs={[{ data: getArraysTotal(stats?.references), label: 'Références' }]}
                    />
                </div>
            </div>

            <div className='carousels-wrap'>
                {users.length > 0 && (
                    <Bloc
                        noBG
                        title='Équipe d’intervention'
                        icon='team'
                        descr='Cliquez sur les photos pour découvrir le profil des intervenants engagés dans le programme.'
                    >
                        <Carousel
                            images={users.map(u => ({
                                src: u?.avatar_url || 'avatar.png',
                                alt: `${u.surname} ${u.name}`,
                                onClick: () =>
                                    Modal.info({
                                        title: `${u.surname} ${u.name}`,
                                        content:
                                            u.bio ||
                                            'Aucune biographie trouvée pour cet intervenant...',
                                        footer: null,
                                        maskClosable: true,
                                        className: 'user-info-modal',
                                    }),
                            }))}
                        />
                    </Bloc>
                )}
                {partners.length > 0 && (
                    <Bloc
                        noBG
                        title='Participants impliqués'
                        icon='participants'
                        descr='Liste des partenaires impliqués dans le programme Action Médiation.'
                    >
                        <Carousel
                            images={partners.map(p => ({
                                src: p?.avatar_url || 'thumb.png',
                                alt: p.name,
                                onClick: () => p.url && window.open(p.url, '_blank'),
                            }))}
                        />
                    </Bloc>
                )}
            </div>
        </div>
    )
}

export default HomePage
