import { getObjectTotal } from './computation'

// TODO: fix la palette de couleurs (j'ai fait un mélange de CLB et des couleurs par défaut de react-vis)
export const colors = [
    'rgb(26, 49, 119)',
    'rgb(18, 147, 154)',
    'rgb(121, 199, 227)',
    '#EF476F',
    '#88CCF1',
    '#CFFFB3',
    '#B8CDF8',
    '#95F2D9',
    '#A1E8AF',
    '#D4B2D8',
]

export const color = (i, offset = 0) => {
    if (i + offset >= colors.length) return '#555555'
    return colors[i + offset]
}

export function transformObjectData(data, xAxis, yAxis, additional = {}) {
    if (!data) return []
    const compute = (key, i) => {
        if (typeof additional === 'function') return additional(key, i)
        if (typeof additional === 'object') return additional
        return null
    }
    return Object.keys(data).map((key, i) => ({
        [xAxis]: key,
        [yAxis]: data[key] || 0,
        ...compute(key, i),
    }))
}

export function flipToPerc(data, xAxis, yAxis, additional = {}, total = undefined) {
    try {
        total = total || getObjectTotal(data)
        return transformObjectData(data, xAxis, yAxis, additional).map(d => ({
            ...d,
            [yAxis]: Math.round((d[yAxis] / total) * 100),
        }))
    } catch (e) {
        return transformObjectData(data, xAxis, yAxis, additional)
    }
}
