import { Header, Helmet } from 'components'
import { TotalTable } from 'components/Charts'
import { useStats } from 'hooks'

const Refuge = props => {
    const { data } = props
    return <TotalTable data={data} height={300} />
}

const Sante = props => {
    const { data } = props
    return <TotalTable data={data} height={300} />
}

const Droits = props => {
    const { data } = props
    return <TotalTable data={data} height={300} />
}

const Centre = props => {
    const { data } = props
    return <TotalTable data={data} height={300} />
}

const Unites = props => {
    const { data } = props
    return <TotalTable data={data} height={300} />
}

const Urgence = props => {
    const { data } = props
    return <TotalTable data={data} height={300} />
}

const Interventions = props => {
    const { data } = props
    const value = data['value']
    return <p className='counter'>{!isNaN(value) ? value : 0}</p>
}

const Logs = props => {
    const { data } = props
    const value = data['value']
    return <p className='counter'>{!isNaN(value) ? value : 0}</p>
}

function ReferencesPage() {
    const { Filters, StatsWrapper, GraphBloc } = useStats('references')

    return (
        <div className='references-page'>
            <Helmet title='Références' />
            <Header title='Références' subtitle='Tableau de bord' filters={Filters} showPrint />

            <StatsWrapper>
                <GraphBloc
                    title="Nombre d'interventions"
                    dataKey='interventions'
                    graph={Interventions}
                />
                <GraphBloc title='Nombre de rapports complétés' dataKey='logs' graph={Logs} />
                <GraphBloc title='Refuge et logement' dataKey='refuge' graph={Refuge} />
                <GraphBloc
                    title='Santé physique, santé mentale et dépendances'
                    dataKey='sante'
                    graph={Sante}
                />
                <GraphBloc title='Droits de la personne' dataKey='droits' graph={Droits} />
                <GraphBloc
                    title='Centre de jour, accompagnement et réinsertion'
                    dataKey='centre'
                    graph={Centre}
                />
                <GraphBloc
                    title='Unités mobiles et travail de rue'
                    dataKey='unites'
                    graph={Unites}
                />
                <GraphBloc title='Urgences et crises' dataKey='urgence' graph={Urgence} />
            </StatsWrapper>
        </div>
    )
}

export default ReferencesPage
