import { useState } from 'react'
import {
    CustomSVGSeries,
    FlexibleXYPlot,
    LabelSeries,
    HorizontalGridLines,
    VerticalGridLines,
} from 'react-vis'
import { color, getObjectTotal } from 'utils'
import ReactResizeDetector from 'react-resize-detector'

const minRadius = 18
const bubbleMargin = 16

function ResponsiveBubble(props) {
    const { minWidth, data, height } = props

    return (
        <ReactResizeDetector handleWidth>
            {({ width }) => {
                const diameter = Math.min(width / data.length - bubbleMargin, height / 2)
                const maxRadius = diameter / 2 || minRadius
                const orientation = width < minWidth ? 'vertical' : 'horizontal'
                return (
                    <Bubble
                        orientation={orientation}
                        maxRadius={maxRadius}
                        width={width}
                        {...props}
                    />
                )
            }}
        </ReactResizeDetector>
    )
}

function Bubble({ data, orientation, width, height, maxRadius, total, percentage }) {
    const [hint, setHint] = useState(false)

    orientation = orientation || 'horizontal'
    total = total || getObjectTotal(data)
    data = (data || []).map(d => ({
        ...d,
        perc: Math.round((d.size / total) * 100) || 0,
        className: hint ? (d.label === hint.label ? 'hovered' : 'not-hovered') : '',
    }))

    if (orientation === 'vertical') {
        data = data.map(d => ({ ...d, x: d.y, y: d.x }))
    }

    const plotProps =
        orientation === 'horizontal'
            ? {
                  xType: 'ordinal',
                  yDomain: [-1, 1],
                  margin: { left: 0, right: 0 },
              }
            : {
                  xDomain: [-1, 1],
                  yType: 'ordinal',
                  margin: { left: 0, right: 0, top: 0, bottom: 0 },
              }

    const seriesProps = orientation === 'horizontal' ? { marginTop: 0.12 * height } : undefined
    const labelPosition = orientation === 'horizontal' ? { y: 0.75 } : { x: -0.9 }

    const renderGridLines = width =>
        orientation === 'horizontal' ? (
            <VerticalGridLines top={0.2 * height} innerHeight={0.7 * height} />
        ) : (
            <HorizontalGridLines left={0.05 * width} innerWidth={0.9 * width} />
        )

    return (
        <FlexibleXYPlot
            className='bubble-wrap'
            width={width || height}
            height={height}
            {...plotProps}
        >
            {renderGridLines(width)}
            <CustomSVGSeries
                className='custom-marking'
                data={data}
                customComponent={d => {
                    return (
                        <g onMouseOver={() => setHint(d)} onMouseOut={() => setHint(false)}>
                            <circle
                                r={Math.min(Math.max(minRadius, d.perc), maxRadius)}
                                fill={color(1)}
                                className={d.className}
                            />
                            <text
                                className='rv-xy-plot__series--label-text'
                                dominantBaseline='middle'
                            >
                                {percentage ? `${d.perc} %` : d.size}
                            </text>
                        </g>
                    )
                }}
                {...seriesProps}
            />
            <LabelSeries
                data={data.map(d => ({ ...d, ...labelPosition }))}
                labelAnchorY='text-after-edge'
                className={orientation}
            />
        </FlexibleXYPlot>
    )
}

export default ResponsiveBubble
