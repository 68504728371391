import React, { Component } from 'react'

import { AgGridReact } from '@ag-grid-community/react'
import { AllCommunityModules } from '@ag-grid-community/all-modules'
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css'

import { updateTableLookups } from 'slices/table.slice'
import { connect } from 'react-redux'
import { AG_GRID_LOCALE_FR } from 'assets/static/locale'

import { Spin, Input, Select, Button, Checkbox, Popover, Tooltip, Modal } from 'antd'
import { Icon } from 'components'
import {
    BoolFloatingFilter,
    DateFloatingFilter,
    DropdownFloatingFilter,
    NumberFloatingFilter,
} from 'components/TableFilters'

import { tableManager } from 'constants/tables'
import { downloadCsvAsXlsx, ls, filterModelToObject, Can, Feedback } from 'utils'
import { SelectService } from 'services'

/**
 * Component : Table
 * ---
 * Renders a table by a given instance.
 * Uses Ag-Grid (https://www.ag-grid.com/documentation-main/documentation.php).
 * @prop    { String }  instance    String giving the instance to fetch in constants/tables.js
 * @prop    { JSX } contextual  JSX to add in the upper right header (buttons)
 */

const formatStorageKey = instance => `table[configs][${instance}]`
class Table extends Component {
    constructor(props) {
        super(props)
        const { instance } = props

        // See /src/constants/tables.js for the instances definitions.
        const params = tableManager(instance)
        if (params === false) throw new Error('Invalid table instance supplied')

        this.state = {
            firstLoadDone: false,
            fetcher: params.fetcher,
            storageKey: formatStorageKey(instance),
            loading: true,
            configs: { pageSize: 25, quickSearch: '' },
            hideWhenEmpty: params.hideWhenEmpty === true,
            showQuickSearch: params.quickSearch === true,
            quickSearchPlaceholder: params.quickSearchPlaceholder || null,
            overwriteRefreshDelay: params.refreshDelay || null,

            modal: {
                opened: false,
                Component: params.FormComponent,
                data: null,
            },

            defaultColDefs: {
                // width: 150,
                suppressMenu: true,
                resizable: true,
                sortable: true,
                filter: 'agTextColumnFilter',
                floatingFilter: true,
                filterParams: {
                    buttons: ['clear'],
                },
                headerValueGetter: ({ column }) => column?.userProvidedColDef?.headerName || '',
                ...(params.defaultColDefs || {}),
            },
            columnTypes: {
                number: {
                    filter: 'agTextColumnFilter',
                    floatingFilterComponent: 'numberFloatingFilter',
                    floatingFilterComponentParams: { min: 0, max: 100, step: 1 },
                },
                date: {
                    filter: 'agTextColumnFilter',
                    floatingFilterComponent: 'dateFloatingFilter',
                    floatingFilterComponentParams: {},
                },
                bool: {
                    filter: 'agTextColumnFilter',
                    floatingFilterComponent: 'boolFloatingFilter',
                    floatingFilterComponentParams: {},
                },
                dropdown: {
                    filter: 'agTextColumnFilter',
                    floatingFilterComponent: 'dropdownFloatingFilter',
                    floatingFilterComponentParams: {},
                },
            },
            columnDefs: params.columnDefs || [],
            onRowClicked: e => {
                if (e.event.target.nodeName === 'A') return

                if (params.onRowClicked) {
                    params.onRowClicked(e)
                } else {
                    this.handleUserAction('open', e.data)
                }
            },
            getRowClass: params.getRowClass || null,
            defaultSortModel: params.defaultSortModel || null,

            frameworkComponents: {
                boolFloatingFilter: BoolFloatingFilter,
                dateFloatingFilter: DateFloatingFilter,
                dropdownFloatingFilter: DropdownFloatingFilter,
                numberFloatingFilter: NumberFloatingFilter,
            },

            actions: params.possibleActions || {},
            rowData: [],
        }

        this.onGridReady = this.onGridReady.bind(this)

        this.setupApiFetcher = this.setupApiFetcher.bind(this)
        this.refreshGrid = this.refreshGrid.bind(this)

        this.onPageSizeChanged = this.onPageSizeChanged.bind(this)
        this.onColumnVisibiltyChanged = this.onColumnVisibiltyChanged.bind(this)
        this.onGridReset = this.onGridReset.bind(this)
        this.onGridExport = this.onGridExport.bind(this)

        this.handleUserAction = this.handleUserAction.bind(this)

        this.loadLookups = this.loadLookups.bind(this)
        this.loadConfigs = this.loadConfigs.bind(this)
        this.saveConfigs = this.saveConfigs.bind(this)

        this._mounted = true
    }

    // ---------------------------------------------------
    // INITIAL CONFIGS
    // ---------------------------------------------------
    componentDidMount() {
        window.addEventListener('beforeunload', this.saveConfigs)
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.saveConfigs)
        this.saveConfigs()

        this._mounted = false
    }

    onGridReady(params) {
        this.gridApi = params.api
        this.gridColumnApi = params.columnApi

        this.loadLookups()
        this.loadConfigs()
    }

    isHidden() {
        if (!this.state.hideWhenEmpty) return false
        if (this.state.loading) return true
        if (!this.gridApi || this.gridApi.getInfiniteRowCount() > 0) return false
        return true
    }

    // ---------------------------------------------------
    // DATA FETCHER
    // ---------------------------------------------------
    // Builds the dataSource by implementing an infinite scroll + pagination.
    // See https://www.ag-grid.com/javascript-grid-infinite-scrolling/
    setupApiFetcher(callback = () => {}) {
        const getRows = function (params) {
            this.setState({ loading: true }, async () => {
                const { startRow, sortModel, filterModel } = params

                // Format the values given by Ag-Grid to pass to the service.
                // Pages setup -->
                let options = {
                    page_number: startRow / this.state.configs.pageSize + 1,
                    per_page: this.state.configs.pageSize,
                }

                // Sorts setup -->
                const sortModelToUse =
                    sortModel && sortModel.length > 0 ? sortModel : this.state.defaultSortModel
                if (sortModelToUse) {
                    const col = sortModelToUse[0]
                    let by = col.colId

                    const { colDef } = this.gridColumnApi.getColumn(col.colId)

                    // Overwrites the name of the sorting key (passed to API)
                    if (colDef && colDef.comparator) by = colDef.comparator

                    options.order_by = by
                    options.order_direction = col.sort
                }

                // Filters setup -->
                if (filterModel)
                    options = {
                        ...options,
                        ...filterModelToObject(filterModel),
                    }

                if (this.state.showQuickSearch && this.state.configs?.quickSearch)
                    options.search = this.state.configs.quickSearch

                if (this.props.fixedFilters)
                    options = {
                        ...options,
                        ...this.props.fixedFilters,
                    }

                // Actual fetch -->
                try {
                    const rowData = await this.state.fetcher(options, this.props.user)
                    if (!this._mounted) return

                    params.successCallback(rowData.data, rowData.total)
                    this.setState({ loading: false }, () => {
                        if (rowData.total === 0) this.gridApi.showNoRowsOverlay()
                        else this.gridApi.hideOverlay()
                        // this.props.updateLastRefreshedIndicator()
                        callback()
                    })
                } catch (e) {
                    if (!this._mounted) return
                    params.failCallback()
                    this.setState({ loading: false }, () => {
                        this.gridApi.showNoRowsOverlay()
                        Feedback.Error(null, e)
                    })
                }
            })
        }.bind(this)

        this.gridApi.setDatasource({
            rowCount: null,
            getRows,
        })
    }

    // Function to call when refreshing the loaded grid.
    // Purge cache + reload selects.
    refreshGrid() {
        this.gridApi.refreshInfiniteCache()
        this.loadLookups()
    }

    // ---------------------------------------------------
    // TABLE ACTIONS
    // ---------------------------------------------------
    onQuickSearchChange(quickSearch) {
        const { configs } = this.state

        this.setState({ configs: { ...configs, quickSearch } }, () => {
            this.gridApi.onFilterChanged()
        })
    }

    onPageSizeChanged(pageSize) {
        const { configs } = this.state
        this.setState({ configs: { ...configs, pageSize } }, () => {
            this.gridApi.paginationSetPageSize(Number(pageSize))
            this.gridApi.gridOptionsWrapper.setProperty('cacheBlockSize', Number(pageSize))
            this.gridApi.purgeInfiniteCache()
            this.setupApiFetcher()
        })
    }

    onColumnVisibiltyChanged(columns) {
        const allColumns = (this.gridColumnApi.getAllColumns() || []).map(c => c.colId)

        this.setState({ visibleColumns: columns }, () => {
            this.gridColumnApi.setColumnsVisible(columns, true)
            this.gridColumnApi.setColumnsVisible(
                allColumns.filter(c => columns.indexOf(c) === -1),
                false
            )
        })
    }

    onGridReset(resetType) {
        switch (resetType) {
            case 'columns':
                this.gridColumnApi.resetColumnState()
                this.setState({
                    visibleColumns: this.gridColumnApi.getAllDisplayedColumns().map(c => c.colId),
                })
                break

            case 'sorts':
                const resetSorts = this.state.configs.columns.map(col => ({ ...col, sort: null }))
                this.gridColumnApi.applyColumnState({ state: resetSorts })
                break

            case 'filters':
                this.gridApi.setFilterModel(null)
                break

            default:
                break
        }
    }

    onGridExport() {
        let csv = this.gridApi.getDataAsCsv()
        downloadCsvAsXlsx(csv, this.props.instance)
    }

    // ---------------------------------------------------
    // USER ACTIONS
    // ---------------------------------------------------
    handleUserAction(type, data = null) {
        const { modal } = this.state

        switch (type) {
            case 'open':
                if (!modal.Component) return
                this.setState({
                    modal: {
                        ...modal,
                        opened: true,
                        mode: 'default',
                        data,
                    },
                })
                break
            case 'create':
                if (!modal.Component) return
                this.setState({
                    modal: {
                        ...modal,
                        opened: true,
                        mode: 'create',
                        data,
                    },
                })
                break
            case 'close':
                this.setState(
                    {
                        modal: {
                            ...modal,
                            data: { id: null },
                        },
                    },
                    () => {
                        this.setState({
                            modal: {
                                ...this.state.modal,
                                opened: false,
                                mode: null,
                            },
                        })

                        if (data === true) this.refreshGrid()
                    }
                )
                break
            default:
                break
        }
    }

    // ---------------------------------------------------
    // LOOKUPS MANAGERS
    // ---------------------------------------------------
    loadLookups() {
        const types = (this.state.columnDefs || [])
            .filter(col => col.type === 'dropdown' && col?.floatingFilterComponentParams?.select)
            .map(col => col.floatingFilterComponentParams.select)

        if (types.length === 0) return
        this.props.fetchLookups(types)
    }

    // ---------------------------------------------------
    // CONFIGS MANAGERS
    // ---------------------------------------------------
    loadConfigs() {
        const { configs, storageKey } = this.state

        // Use store to get user's preferences for this instance.
        const preferences = ls.get(storageKey)
        this.setState({ configs: { ...configs, ...preferences } }, () => {
            if (this.state.configs.columns)
                this.gridColumnApi.setColumnState(this.state.configs.columns)
            if (this.state.configs.pageSize) {
                this.gridApi.paginationSetPageSize(this.state.configs.pageSize)
                this.gridApi.gridOptionsWrapper.setProperty(
                    'cacheBlockSize',
                    this.state.configs.pageSize
                )
            }

            // this.gridApi.setSortModel(this.state.configs.sort)
            this.gridApi.setFilterModel(this.state.configs.filters)
            this.setupApiFetcher(() => {
                if (this.state.firstLoadDone) return
                this.gridApi.paginationGoToPage(this.state.configs.currentPage || 0)
                this.setState({ firstLoadDone: true })
            })

            // Only for the Checkboxs, the real show/hide is handled in the ColumnState.
            this.setState({
                visibleColumns: this.gridColumnApi.getAllDisplayedColumns().map(c => c.colId),
            })
        })
    }

    saveConfigs() {
        const { storageKey } = this.state
        if (!this.gridColumnApi || !this.gridApi) return

        const configs = {
            ...this.state.configs,
            columns: this.gridColumnApi.getColumnState(),
            pageSize: this.gridApi.paginationGetPageSize(),
            // sort: this.gridApi.getSortModel(),
            filters: this.gridApi.getFilterModel(),
            currentPage: this.gridApi.paginationGetCurrentPage(),
        }

        ls.set(storageKey, configs)
    }

    // ---------------------------------------------------
    // RENDER
    // ---------------------------------------------------
    render() {
        const { actions, modal } = this.state

        // TODO : Implement actions handling/buttons for edit + delete

        const options = (
            <div className='table-popover-options'>
                <div className='ctn columns-ctn'>
                    <label className='label-title'>Colonnes à afficher :</label>
                    <Checkbox.Group
                        options={this.state.columnDefs.map(c => ({
                            label: c.headerName,
                            value: c.field,
                        }))}
                        value={this.state.visibleColumns || []}
                        onChange={values => this.onColumnVisibiltyChanged(values)}
                    />
                </div>

                <hr />

                <div className='ctn reset-ctn'>
                    <label className='label-title'>Réinitialiser :</label>
                    <Button.Group>
                        <Button size='small' onClick={() => this.onGridReset('filters')}>
                            Filtres
                        </Button>
                        <Button size='small' onClick={() => this.onGridReset('sorts')}>
                            Tris
                        </Button>
                        <Button size='small' onClick={() => this.onGridReset('columns')}>
                            Colonnes
                        </Button>
                    </Button.Group>
                </div>
            </div>
        )

        return (
            <div className='sds-table table-wrap' data-empty={this.isHidden()}>
                <div className='table-menu'>
                    {this.state.showQuickSearch && (
                        <Input.Search
                            placeholder={
                                this.state.quickSearchPlaceholder || 'Entrer un terme de recherche'
                            }
                            value={this.state.configs.quickSearch}
                            onChange={event => this.onQuickSearchChange(event.target.value)}
                        />
                    )}

                    <Popover content={options} trigger='click' placement='bottomLeft'>
                        <Tooltip placement='top' title='Options du tableau'>
                            <Button className='grey'>
                                <Icon>options</Icon>
                            </Button>
                        </Tooltip>
                    </Popover>

                    <Tooltip placement='top' title='Télécharger en format XLSX'>
                        <Button className='grey' onClick={this.onGridExport}>
                            <Icon>download</Icon>
                        </Button>
                    </Tooltip>

                    {'create' in actions && (
                        <Can do={actions.create.ability?.do} on={actions.create.ability?.on}>
                            <Button
                                className='main'
                                onClick={() => this.handleUserAction('create')}
                            >
                                <Icon style={{ fontWeight: '700' }}>add</Icon>
                                {actions.create.label}
                            </Button>
                        </Can>
                    )}
                </div>

                <Spin spinning={this.state.loading}>
                    <div className='ag-theme-balham' style={{ height: '100%', width: '100%' }}>
                        <AgGridReact
                            domLayout='autoHeight'
                            rowModelType='infinite'
                            cacheBlockSize={this.state.configs.pageSize}
                            modules={AllCommunityModules}
                            pagination={true}
                            suppressDragLeaveHidesColumns={true}
                            blockLoadDebounceMillis={100}
                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDefs}
                            columnTypes={this.state.columnTypes}
                            onGridReady={this.onGridReady}
                            frameworkComponents={this.state.frameworkComponents}
                            onRowClicked={this.state.onRowClicked}
                            getRowClass={this.state.getRowClass}
                            localeText={AG_GRID_LOCALE_FR}
                        />

                        <div className='table-menu bottom'>
                            <div className='ctn'>
                                <label>Entrées par page :</label>
                                <Select
                                    size='small'
                                    value={this.state.configs.pageSize}
                                    onChange={value => this.onPageSizeChanged(value)}
                                >
                                    <Select.Option value={10}>10</Select.Option>
                                    <Select.Option value={25}>25</Select.Option>
                                    <Select.Option value={50}>50</Select.Option>
                                    <Select.Option value={100}>100</Select.Option>
                                    <Select.Option value={500}>500</Select.Option>
                                </Select>
                            </div>
                            <div className='placeholder'></div>
                        </div>
                    </div>
                </Spin>

                <Modal
                    visible={modal.opened}
                    onCancel={() => this.handleUserAction('close')}
                    footer={null}
                    className='form-modal table-modal'
                    maskClosable={false}
                >
                    <modal.Component
                        key={modal.mode}
                        mode={modal.mode}
                        actions={actions}
                        onClose={forceRefresh => this.handleUserAction('close', forceRefresh)}
                        baseData={modal.data}
                    />
                </Modal>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchLookups: async lookups => {
            if (!Array.isArray(lookups) || lookups.length === 0)
                throw new Error('Invalid lookups given')

            try {
                const response = await SelectService.list(lookups)
                dispatch(updateTableLookups(response))
                return lookups
            } catch (e) {
                Feedback.Error('loading-selects', e)
            }
        },
    }
}

export default connect(null, mapDispatchToProps)(Table)
