import { Popover } from 'antd'
import { ExternalLink } from 'components'
import {
    TableLogForm,
    TablePartnerForm,
    TableUserForm,
    TableLocationForm,
    TableOrganisationForm,
    TableTestimonialForm,
} from 'components/TableForms'
import ReactDOMServer from 'react-dom/server'
import {
    LogService,
    UserService,
    PartnerService,
    LocationService,
    OrganisationService,
    TestimonialService,
} from 'services'
import { Formatter, rules } from 'utils'
import { CREATE, DELETE, describe, EDIT, listify } from './misc'

/**
 * Constants : Tables
 * ---
 * File that stores the params for all the different
 * instance of tables.
 * See /src/components/Table.js + Ag-Grid for more informations.
 *
 * INSTANCE { Object }
 * @prop    { Function }    fetcher     The service's function to fetch the data in that table
 * @prop    { Boolean }     quickSearch     Show the quickSearch input or no
 * @prop    { Int }         refreshDelay    The interval at which the table should refresh.
 * @prop    { Object }      defaultSortModel     Default SortModel for this table. See Ag-Grid for more informations on SortModel.
 * @prop    { Object }      defaultColDefs  Ag-Grid attribute
 * @prop    { Array }       columnDefs      Ag-Grid attribute (insert all the columns for this table, headerName will be translated)
 * @prop    { Function }    onRowClicked    Ag-Grid attribute
 * @prop    { Function }    getRowClass     Ag-Grid attribute
 * @prop    { Object }      possibleActions     List of the possible actions (edit, create, delete) + params (event, ability)
 *
 * WARNING : Sometimes the data read in the valueGetters/cellRenderers might be undefined (while fetching). Must plan in consequence.
 * WARNING : If the sort for a column passes through another DB Table, use columnDefs.comparator --> ${table_name}.${field}. See triggered_alarms
 */

export const tableRender = jsx => ReactDOMServer.renderToString(jsx)

// ----------------------------------
// -------------- LOGS --------------
// ----------------------------------
const logs = {
    fetcher: (params, user) =>
        LogService.list({
            order_by: 'created_at',
            order_direction: 'desc',
            ...params,
            user_id: user?.infos?.rang === 1 || user?.infos?.rang === 3 ? null : user?.infos?.id,
        }),
    columnDefs: [
        {
            headerName: 'Statut',
            field: 'status',
            valueGetter: ({ data }) => describe('log-status', data?.status)?.label || '',
            type: 'dropdown',
            floatingFilterComponentParams: {
                options: listify('log-status'),
            },
        },
        {
            headerName: 'Horaire',
            field: 'schedule_id',
            comparator: 'forms.schedule_id',
            valueGetter: ({ data }) => data?.form?.schedule?.label,
            type: 'dropdown',
            floatingFilterComponentParams: {
                select: 'Schedule',
            },
        },
        {
            headerName: 'Date',
            field: 'date',
            comparator: 'forms.date',
            valueGetter: ({ data }) => (data?.form?.date ? Formatter.Date(data?.form?.date) : '-'),
            type: 'date',
        },
        {
            headerName: 'Description',
            field: 'description',
            valueGetter: ({ data }) => data?.description,
        },
        {
            headerName: 'Lieu',
            field: 'location_id',
            comparator: 'forms.location_id',
            valueGetter: ({ data }) => data?.form?.location?.label,
            type: 'dropdown',
            floatingFilterComponentParams: {
                select: 'Location',
            },
        },
        {
            headerName: 'Intervenant',
            field: 'user_name',
            comparator: 'users.name',
            valueGetter: ({ data }) => `${data?.user?.surname || ''} ${data?.user?.name || ''}`,
        },
        {
            headerName: 'Partenaire',
            field: 'partner_id',
            comparator: 'forms.partner_id',
            valueGetter: ({ data }) => data?.form?.partner?.label,
            type: 'dropdown',
            floatingFilterComponentParams: {
                select: 'Partner',
            },
        },
        {
            headerName: 'Nb Hommes',
            field: 'sexe_homme',
            comparator: 'forms.sexe_homme',
            valueGetter: ({ data }) => data?.form?.sexe_homme,
            type: 'number',
        },
        {
            headerName: 'Nb Femmes',
            field: 'sexe_femme',
            comparator: 'forms.sexe_femme',
            valueGetter: ({ data }) => data?.form?.sexe_femme,
            type: 'number',
        },
        {
            headerName: 'Nb Trans',
            field: 'sexe_trans',
            comparator: 'forms.sexe_trans',
            valueGetter: ({ data }) => data?.form?.sexe_trans,
            type: 'number',
        },
        {
            headerName: 'Binôme',
            field: 'binome_name',
            comparator: 'binomes.name',
            valueGetter: ({ data }) => `${data?.binome?.surname || ''} ${data?.binome?.name || ''}`,
        },
        {
            headerName: 'Accompagnement',
            field: 'accompagnement_id',
            valueGetter: ({ data }) => data?.accompagnement?.name,
            type: 'dropdown',
            floatingFilterComponentParams: {
                select: 'Organisation',
                optionsParams: { label: 'name' },
            },
        },
        {
            headerName: 'Réference',
            field: 'referent_id',
            valueGetter: ({ data }) => data?.referent?.name,
            type: 'dropdown',
            floatingFilterComponentParams: {
                select: 'Organisation',
                optionsParams: { label: 'name' },
            },
        },
        {
            headerName: 'Signalement (Orga)',
            field: 'signalement_orga_id',
            valueGetter: ({ data }) => data?.signalement_orga?.name,
            type: 'dropdown',
            floatingFilterComponentParams: {
                select: 'Organisation',
                optionsParams: { label: 'name' },
            },
        },
        {
            headerName: 'Signalement (Loc)',
            field: 'signalement_loc_id',
            valueGetter: ({ data }) => data?.signalement_loc?.label,
            type: 'dropdown',
            floatingFilterComponentParams: {
                select: 'Location',
            },
        },
    ],
    FormComponent: TableLogForm,
    possibleActions: {
        edit: {
            ability: { do: EDIT, on: 'Log' },
            handler: LogService.update,
        },
        del: {
            ability: { do: DELETE, on: 'Log' },
            handler: LogService.del,
        },
    },
}

const users = {
    fetcher: params => UserService.list(params),
    columnDefs: [
        {
            headerName: 'Rang',
            field: 'rang',
            valueGetter: ({ data }) => describe('user-ranks', data?.rang)?.label || '',
            type: 'dropdown',
            floatingFilterComponentParams: {
                options: listify('user-ranks'),
            },
        },
        {
            headerName: 'Nom',
            field: 'name',
            valueGetter: ({ data }) => `${data?.surname || ''} ${data?.name || ''}`,
        },
        {
            headerName: 'Courriel',
            field: 'email',
        },
        {
            headerName: 'Téléphone',
            field: 'phone',
            valueGetter: ({ data }) =>
                `${data?.phone.trim().replace(rules.phone.pattern, '($1) $2-$3') || ''}`,
        },
        {
            headerName: 'Poste',
            field: 'poste',
        },
    ],
    FormComponent: TableUserForm,
    possibleActions: {
        create: {
            label: 'Nouvel utilisateur',
            ability: { do: CREATE, on: 'User' },
            handler: UserService.create,
        },
        edit: {
            ability: { do: EDIT, on: 'User' },
            handler: UserService.update,
        },
        del: {
            ability: { do: DELETE, on: 'User' },
            handler: UserService.del,
        },
    },
}

const partners = {
    fetcher: params => PartnerService.list(params),
    columnDefs: [
        {
            headerName: 'Nom',
            field: 'label',
        },
        {
            headerName: 'Logo',
            field: 'avatar_url',
            sortable: false,
            filter: false,
            floatingFilter: false,
            valueGetter: ({ data }) => data,
            cellRendererFramework: ({ value: data }) => (
                <Popover
                    overlayClassName='table-popover'
                    content={<img src={data?.avatar_url} alt={data?.label}></img>}
                >
                    <ExternalLink targetBlank label='Image' button>
                        {data?.avatar_url}
                    </ExternalLink>
                </Popover>
            ),
        },
        {
            headerName: 'URL',
            field: 'url',
            cellRendererFramework: ({ value }) => <ExternalLink targetBlank>{value}</ExternalLink>,
        },
        {
            headerName: 'Utilisateur',
            field: 'users',
            sortable: false,
            filter: false, // TODO: faire marcher le filtre sur cette colonne
            floatingFilter: false,
            valueGetter: ({ data }) => {
                return (data?.users || [])
                    .map(user => `${user?.surname || ''} ${user?.name || ''}`)
                    .join(', ')
            },
        },
    ],
    FormComponent: TablePartnerForm,
    possibleActions: {
        create: {
            label: 'Nouveau partenaire',
            ability: { do: CREATE, on: 'Partner' },
            handler: PartnerService.create,
        },
        edit: {
            ability: { do: EDIT, on: 'Partner' },
            handler: PartnerService.update,
        },
        del: {
            ability: { do: DELETE, on: 'Partner' },
            handler: PartnerService.del,
        },
    },
}

const locations = {
    fetcher: params => LocationService.list(params),
    columnDefs: [
        {
            headerName: 'Catégorie',
            field: 'categorie_id',
            type: 'dropdown',
            valueGetter: ({ data }) => data?.category?.label,
            floatingFilterComponentParams: {
                select: 'LocationCategory',
            },
        },
        {
            headerName: 'Nom',
            field: 'label',
        },
        {
            headerName: 'Adresse',
            field: 'address',
        },
        {
            headerName: 'Archiver',
            field: 'is_archive',
            type: 'bool',
            valueGetter: ({ data }) => (data?.is_archive ? 'Oui' : 'Non'),
        },
    ],
    FormComponent: TableLocationForm,
    possibleActions: {
        create: {
            label: 'Nouveau lieu',
            ability: { do: CREATE, on: 'Location' },
            handler: LocationService.create,
        },
        edit: {
            ability: { do: EDIT, on: 'Location' },
            handler: LocationService.update,
        },
        del: {
            ability: { do: DELETE, on: 'Location' },
            handler: LocationService.del,
        },
    },
}

const organisations = {
    fetcher: params => OrganisationService.list(params),
    columnDefs: [
        {
            headerName: 'Catégorie',
            field: 'categorie_id',
            type: 'dropdown',
            valueGetter: ({ data }) => data?.category?.label,
            floatingFilterComponentParams: {
                select: 'OrganisationCategory',
            },
        },
        {
            headerName: 'Nom',
            field: 'name',
        },
        {
            headerName: 'URL',
            field: 'url',
            cellRendererFramework: ({ value }) => <ExternalLink targetBlank>{value}</ExternalLink>,
        },
    ],
    FormComponent: TableOrganisationForm,
    possibleActions: {
        create: {
            label: 'Nouvel organisme',
            ability: { do: CREATE, on: 'Organisation' },
            handler: OrganisationService.create,
        },
        edit: {
            ability: { do: EDIT, on: 'Organisation' },
            handler: OrganisationService.update,
        },
        del: {
            ability: { do: DELETE, on: 'Organisation' },
            handler: OrganisationService.del,
        },
    },
}

const testimonies = {
    fetcher: params => TestimonialService.list(params),
    columnDefs: [
        {
            headerName: 'Entreprise',
            field: 'corporation',
        },
        {
            headerName: 'Fonction',
            field: 'function',
        },
        {
            headerName: 'Nom',
            field: 'name',
        },
        {
            headerName: 'Témoignage',
            field: 'text',
        },
    ],
    FormComponent: TableTestimonialForm,
    possibleActions: {
        create: {
            label: 'Nouveau témoignage',
            ability: { do: CREATE, on: 'Testimonial' },
            handler: TestimonialService.create,
        },
        edit: {
            ability: { do: EDIT, on: 'Testimonial' },
            handler: TestimonialService.update,
        },
        del: {
            ability: { do: DELETE, on: 'Testimonial' },
            handler: TestimonialService.del,
        },
    },
}

export function tableManager(instance) {
    switch (instance) {
        case 'logs':
            return logs
        case 'logs-with-create':
            const hidden = [
                'date',
                'location_id',
                'user_name',
                'partner_id',
                'sexe_homme',
                'sexe_femme',
                'sexe_trans',
            ]
            return {
                ...logs,
                columnDefs: logs.columnDefs.map(c => {
                    if (hidden.indexOf(c.field) !== -1) c.hide = true
                    return c
                }),
                possibleActions: {
                    ...logs.possibleActions,
                    create: {
                        label: 'Ajouter une nouvelle entrée au journal de bord',
                        ability: { do: CREATE, on: 'Log' },
                        handler: LogService.create,
                    },
                },
                hideWhenEmpty: true,
            }
        case 'users':
            return users
        case 'partners':
            return partners
        case 'locations':
            return locations
        case 'organisations':
            return organisations
        case 'testimonies':
            return testimonies

        // Catch
        default:
            return false
    }
}
