import mapboxgl from 'mapbox-gl'
import ReactResizeDetector from 'react-resize-detector'
import { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Spin } from 'antd'

import { MAPBOX_TOKEN } from 'constants/api'
import { USER_RANK_PARTNER } from 'constants/misc'
import { UserService } from 'services'
import { useState } from 'react'
mapboxgl.accessToken = MAPBOX_TOKEN

const DEFAULT_LAT = 45.53884
const DEFAULT_LNG = -73.58781
const DEFAULT_ZOOM = 11
const MAPBOX_STYLES = 'felix04h11/ckcq97axl17lv1kob36qnzhlo'

function toGeoJson(data) {
    return {
        type: 'FeatureCollection',
        features: (data || []).map(d => ({
            type: 'Feature',
            properties: { id: d.id, name: d.name, inters: d.inters },
            geometry: { type: 'Point', coordinates: [d.lng, d.lat] },
        })),
    }
}

const cluster_steps = (key = 'total_points') => ['step', ['get', key], '#0094c6', 750, '#fe512c']

const cluster_styles = (key = 'total_points') => ({
    'circle-color': cluster_steps(key),
    'circle-stroke-color': cluster_steps(key),
    'circle-radius': ['step', ['get', key], 20, 100, 30, 750, 40],
    'circle-stroke-opacity': 0.6,
    'circle-stroke-width': 3,
    'circle-opacity': 0.4,
    'circle-opacity-transition': { duration: 800 },
})

const dot_styles = (key = 'inters') => ({
    'circle-color': '#bababa',
    'circle-radius': ['step', ['get', key], 15, 100, 20, 750, 30],
    'circle-opacity': 0.4,
})

function Map({ data }) {
    const user = useSelector(state => state.user)
    const isPublic = !user.logged || user.infos.public

    const mapContainer = useRef(null)
    const map = useRef(null)

    const [loading, setLoading] = useState(true)

    const load = async () => {
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: `mapbox://styles/${MAPBOX_STYLES}`,
            center: [DEFAULT_LNG, DEFAULT_LAT],
            zoom: DEFAULT_ZOOM,
            maxZoom: isPublic ? 12 : 16,
            minZoom: 8,
        })

        let formattedData = [...data]
        if (user.logged && user?.infos?.rang === USER_RANK_PARTNER) {
            const { partners = [] } = await UserService.get(user?.infos?.id)
            formattedData = data.filter(d => partners.some(p => (d.partners || []).includes(p)))
        }

        map.current.on('load', () => {
            map.current.addSource('interventions', {
                type: 'geojson',
                data: toGeoJson(formattedData),
                cluster: true,
                clusterMaxZoom: isPublic ? 12 : 13,
                clusterRadius: 100,
                clusterProperties: { total_points: ['+', ['get', 'inters']] },
            })

            map.current.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'interventions',
                filter: ['==', 'cluster', true],
                paint: cluster_styles(),
            })

            map.current.addLayer({
                id: 'unclustered-points',
                type: 'circle',
                source: 'interventions',
                filter: ['!', ['has', 'total_points']],
                paint: isPublic ? cluster_styles('inters') : dot_styles(),
            })

            map.current.addLayer({
                id: 'cluster-counts',
                type: 'symbol',
                source: 'interventions',
                filter: ['==', 'cluster', true],
                paint: {
                    'text-color': cluster_steps(),
                },
                layout: {
                    'text-field': ['get', 'total_points'],
                    'text-size': 14,
                },
            })

            map.current.addLayer({
                id: 'unclustered-counts',
                type: 'symbol',
                source: 'interventions',
                filter: ['!', ['has', 'total_points']],
                paint: {
                    'text-color': isPublic ? cluster_steps('inters') : '#727272',
                },
                layout: {
                    'text-field': ['get', 'inters'],
                    'text-size': isPublic ? 14 : 11,
                },
            })

            map.current.on('click', 'clusters', e => {
                const features = map.current.queryRenderedFeatures(e.point, {
                    layers: ['clusters'],
                })
                const clusterId = features[0].properties.cluster_id
                map.current
                    .getSource('interventions')
                    .getClusterExpansionZoom(clusterId, (err, zoom) => {
                        if (err) return
                        map.current.easeTo({
                            center: features[0].geometry.coordinates,
                            zoom: zoom,
                        })
                    })
            })

            map.current.on('mouseenter', 'clusters', () => {
                map.current.getCanvas().style.cursor = 'pointer'
            })

            map.current.on('mouseleave', 'clusters', () => {
                map.current.getCanvas().style.cursor = ''
            })

            setLoading(false)
        })
    }

    useEffect(() => {
        if (!data) return
        load()

        return () => {
            map.current.remove()
            setLoading(true)
        }
    }, [data])

    return (
        <div className='map'>
            <ReactResizeDetector onResize={() => map.current && map.current.resize()}>
                <div className='map-container' ref={mapContainer}>
                    <Spin size='large' spinning={loading} />
                </div>
            </ReactResizeDetector>
        </div>
    )
}

export default Map
